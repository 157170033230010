import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import AddProductForm from "./AddProductForm/AddProductForm";

const EditProductComponent = () => {
    const [currentProduct, setCurrentProduct] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);

    useEffect(() => {
        const parsedUrl = window.location.pathname.split('/');
        const currentProductId = parsedUrl[parsedUrl.length - 2];

        $.get(`/internal-api/profile/products/${currentProductId}/edit`)
            .done(({ data }) => {
                setCurrentProduct(data);
            }).fail(() => {
                console.log( "error" );
            });
    }, []);

    const handleDeleteOldImg = useCallback((imgId) => {
        setDeletedImages((prevState) => [...prevState, imgId])
    }, []);

    const saveProduct = useCallback((newImages, featuredImage, formValues) => {
        const formData = new FormData();
        // console.log(formValues, JSON.stringify(formValues))
        if (newImages.length > 0 ) {
            newImages.forEach(({ file }, index) => {
                formData.set(`images[${index}]`, file);
            });
        }

        if (deletedImages.length > 0) {
            formData.set('deletedImages', JSON.stringify(deletedImages));
        }

        formData.set('featuredImage', featuredImage);
        formData.set('payloads', JSON.stringify(formValues));

        const parsedUrl = window.location.pathname.split('/');
        const currentProductId = parsedUrl[parsedUrl.length - 2];
        return new Promise((resolve) => {
            $.ajax({
                url: `/internal-api/profile/products/${currentProductId}/update`,
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                success: (res) => {
                    resolve(true);
                    const currentLocale = window.location.pathname.split('/')[1];
                    window.location.href = `${window.location.origin}/${currentLocale}/profile/seller/products`;
                },
                error: (err) => {
                    resolve(err.responseJSON);
                }
            });
        });
    }, [deletedImages]);

    return (
        <AddProductForm
            currentProduct={currentProduct}
            onDeleteOldImg={handleDeleteOldImg}
            onSubmitForm={saveProduct}
        />
    )
};

if (document.getElementById('edit-product-form-placeholder')) {
    ReactDOM.render(<EditProductComponent />, document.getElementById('edit-product-form-placeholder'));
}

export default EditProductComponent;
