const emailVerification = {
    init() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const showModal = urlParams.get('showVerifyEmailModal');
        const verifyEmailModal = $('#verifyEmailModal');

        if (showModal && verifyEmailModal) {
            verifyEmailModal.modal('show')
        }

        $('#resend-email-btn').on('click', () => {
            $.ajax({
                url: 'email/verification-notification',
                type: 'POST',
                dataType: 'json',
                success: function () {
                    // console.log('Success')
                },
                error: (err) => {
                    console.log('Error:', err);
                }
            });
        })
    }
};

export default emailVerification;
