const priceRange = {
    createRangeInstance(element, initialValues, updateHandler, maxPrice = 250) {


        noUiSlider.create(element, {
            start: initialValues,
            range: {
                'min': [0],
                'max': maxPrice
            },
            connect: true,
            format: {
                from: (value) => Number(value),
                to: (value) => Math.round(value),
            }
        });

        element.noUiSlider.on('update', updateHandler);
    },

    initProfileProductsRange() {
        const profileProductsRange = document.getElementById('products-filters-range-slider');
        const rangeFromInput = $('#rangeFrom');
        const rangeToInput = $('#rangeTo');
        const rangeFromLabel = $('#rangeFrom-label');
        const rangeToLabel = $('#rangeTo-label');


        if (profileProductsRange) {
            const updateHandler = function(values, handle) {
                const inputElement = handle === 0 ? rangeFromInput : rangeToInput;
                const labelElement = handle === 0 ? rangeFromLabel : rangeToLabel;
                inputElement.val(values[handle]);
                labelElement.text(values[handle]);
            };

            this.createRangeInstance(profileProductsRange, [+rangeFromInput.val(), +rangeToInput.val()], updateHandler, +rangeToInput.attr('max'));
        }
    },

    init() {
        const mobileRange = document.getElementById('range-slider-mobile');
        const desktopRange = document.getElementById('range-slider-desktop');

        if (mobileRange && desktopRange) {
            const rangeFromInput = $('#rangeFrom-mobile');
            const rangeToInput = $('#rangeTo-mobile');

            const desktopRangeFromInput = $('#rangeFrom-desktop');
            const desktopRangeToInput = $('#rangeTo-desktop');
            const desktopMaxPrice = Number(desktopRangeToInput.attr('max'));
            const mobileMaxPrice = Number(rangeToInput.attr('max'));

            const initialValues = [Number(rangeFromInput.val()), Number(rangeToInput.val())];

            const updateMobileRange = function (values, handle) {
                const inputElement = handle === 0 ? rangeFromInput : rangeToInput;
                inputElement.val(values[handle]);
            };

            const updateDesktopRange = function (values, handle) {
                const inputElement = handle === 0 ? desktopRangeFromInput : desktopRangeToInput;
                inputElement.val(values[handle]);
            };

            this.createRangeInstance(mobileRange, initialValues, updateMobileRange, mobileMaxPrice);
            this.createRangeInstance(desktopRange, initialValues, updateDesktopRange, desktopMaxPrice);

            mobileRange.noUiSlider.on('update', function (values, handle) {
                const inputElement = handle === 0 ? rangeFromInput : rangeToInput;
                inputElement.val(values[handle]);
            });

            desktopRange.noUiSlider.on('update', function (values, handle) {
                const inputElement = handle === 0 ? desktopRangeFromInput : desktopRangeToInput;
                inputElement.val(values[handle]);
            });

            rangeFromInput.on('change', function () {
                const newValue = Number($(this).val());
                mobileRange.noUiSlider.setHandle(0, newValue, true, true);
            });

            rangeToInput.on('change', function () {
                const newValue = Number($(this).val());
                mobileRange.noUiSlider.setHandle(1, newValue, true, true);
            });

            desktopRangeFromInput.on('change', function () {
                const newValue = Number($(this).val());
                desktopRange.noUiSlider.setHandle(0, newValue, true, true);
            });

            desktopRangeToInput.on('change', function () {
                const newValue = Number($(this).val());
                desktopRange.noUiSlider.setHandle(1, newValue, true, true);
            });
        }

        this.initProfileProductsRange();
    }
};

export default priceRange;
