import intl from "./i18n/translate";

const contactUs = {
    init() {
        $(document).on('click', '.modal-submit-button', function () {

            let name = $("#contact-support-name-field").val(),
                email = $("#contact-support-email-field").val(),
                text = $("#contact-support-text-field").val();

            sendEmail(name, email, text);
        });

        $(document).on('click', '.send-contact-us-form', function () {
            let name = $("#support-request-name").val(),
                email = $("#support-request-email").val(),
                text = $("#support-request-text").val();

            sendEmail(name, email, text);
        })

        function sendEmail(name, email, text)
        {
            $.ajax({
                url: `/internal-api/contact-us`,
                data: {
                    'name' : name,
                    'email' : email,
                    'message': text
                },
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    toastr.success(intl.translate('toastrMessage.contact-us.success.body'), intl.translate('toastrMessage.contact-us.success.head'));
                    $('.closeBtn').trigger('click');
                },
                error: (err) => {
                    toastr.error(null, err.responseJSON.error[0]);
                }
            });
        }
    }
};

export default contactUs;
