const passwordInput = {
    toggleVisiblePassword(e) {
        const passwordInput = e.currentTarget.previousElementSibling;
        const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
        passwordInput.setAttribute('type', type);
        $(e.currentTarget).toggleClass('shown');
    },
    init() {
        const toggleVisiblePassword = this.toggleVisiblePassword.bind(this);
        $('.togglePassword').on('click', toggleVisiblePassword);
    }
}

export default passwordInput;
