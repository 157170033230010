import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import ProductImagesUpload from "./AddProductForm/components/ProductImagesUpload";
import {Field, Form} from "react-final-form";
import TextAreaWrapper from "./finalFormWrappers/TextAreaWrapper";
import Rating from "react-rating";
import intl from "../i18n/translate";

const createReviewValidator = ({ message }) => {
    const errors = {};

    if (!message) {
        errors.message = [intl.translate('validationError.required')];
    }

    return errors;
};

const CreateReviewForm = () => {
    const [productId, setProductId] = useState(null);
    const [rating, setRating] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const createReviewBtn = document.getElementById('createReviewBtn');
        if (createReviewBtn) {
            const { productId: prodId } = createReviewBtn.dataset;
            setProductId(prodId);
        }
    }, []);

    const updateImageList = useCallback((list) => {
        setImages(list);
    }, []);

    const handleDeleteImg = useCallback(({ currentTarget }) => {
        const { fileName } = currentTarget.dataset;
        const filteredImages = images.filter(({ file }) => fileName !== file.name);

        setImages(filteredImages);
    }, [images]);

    const handleSubmitForm = useCallback(({ message }) => {
        const formData = new FormData();

        if (images.length > 0 ) {
            images.forEach(({ file }, index) => {
                formData.set(`image[${index}]`, file);
            });
        }

        formData.set('message', message);
        formData.set('rating', rating);

        return new Promise((resolve) => {
            $.ajax({
                url: '/internal-api/products/' + productId + '/reviews',
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                success: (res) => {
                    resolve(true);
                    $('#createReviewModal').modal('hide');
                    $('#createReviewBtn').hide();
                },
                error: (err) => {
                    resolve(err.responseJSON.errors);
                }
            });
        });
    }, [images, rating]);

    const handleRatingChange = useCallback((value) => {
        setRating(value);
    }, [])

    return (
        <Form
            onSubmit={handleSubmitForm}
            validate={createReviewValidator}
            render={({ handleSubmit, submitErrors }) => (
                <form onSubmit={handleSubmit}>
                    <p className="mb-2">{intl.translate('createReview.modalTitle')}</p>
                    <div className="mb-3">
                        <Rating
                            initialRating={rating}
                            onChange={handleRatingChange}
                            emptySymbol={
                                <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z" fill="#E1EBFB"/>
                                </svg>
                            }
                            fullSymbol={
                                <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0.5L9.79611 6.02786H15.6085L10.9062 9.44427L12.7023 14.9721L8 11.5557L3.29772 14.9721L5.09383 9.44427L0.391548 6.02786H6.20389L8 0.5Z" fill="#FFD600"/>
                                </svg>
                            }
                        />
                        {submitErrors?.rating && !rating && (
                            <p className="invalid-feedback d-block mt-1">{submitErrors.rating}</p>
                        )}
                    </div>
                    <Field
                        name="message"
                        placeholder={intl.translate('createReview.inputPlaceholder')}
                        component={TextAreaWrapper}
                    />
                    <ProductImagesUpload
                        images={images}
                        onUpdateImages={updateImageList}
                        onDeleteImg={handleDeleteImg}
                        maxFiles={6}
                    />
                    <button className="basicBtn mt-3">{intl.translate('createReview.submitBtnText')}</button>
                </form>
            )}
        />
    )
};

if (document.getElementById('create-review-placeholder')) {
    ReactDOM.render(<CreateReviewForm />, document.getElementById('create-review-placeholder'));
}

export default CreateReviewForm;
