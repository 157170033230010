import intl from "../i18n/translate";

const resetPasswordValidator = ({ password, password_confirmation }) => {
    const errors = {};

    if (!password) {
        errors.password = [intl.translate('validationError.required')];
    }

    if (password && !password_confirmation) {
        errors.password_confirmation = [intl.translate('validationError.required')];
    } else if (password !== password_confirmation) {
        errors.password_confirmation = [intl.translate('validationError.passwordsShouldMatch')];
    }

    return errors;
};

export default resetPasswordValidator;
