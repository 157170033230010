import React from 'react';
import Select from "react-select";
import intl from "../../i18n/translate";

const MultiSelectInput = (
    {
        options,
        isDisabled ,
        value,
        onChange,
    }
) => (
    <Select
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        isMulti
        placeholder={intl.translate('addProductForm.select')}
        options={options}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
    />
)

export default MultiSelectInput;
