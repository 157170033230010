const initTabs = (elementId) => {
    const tabItemElement = $(`#${elementId} .tabItem`);

    if (tabItemElement.length) {
        const { for: firstTabId } = tabItemElement.first()[0].dataset;

        $(`#${elementId} [data-tabId=${firstTabId}]`).addClass('visible');
        tabItemElement.first().addClass('active');

        tabItemElement.on('click', function() {
            $(`#${elementId} .tab-content`).each(function () {
                $(this).removeClass('visible');
            });

            const { for: categoryId } = $(this)[0].dataset;

            $(`#${elementId} [data-tabId=${categoryId}]`).addClass('visible');
        });
    }
};

const tabs = {
    init: () => {
        $('.tabItem').on('click', function() {
            $(this).siblings().each(function() {
                $(this).removeClass('active');
            });

            $(this).addClass('active');
        });

        const tabElement = document.querySelector('.js-tabs .tabItem.active');
        if (tabElement) {
            const tabOffset = document.querySelector('.js-tabs .tabItem.active').offsetLeft;
            $('.js-tabs').scrollLeft(tabOffset);
        }

        initTabs('category-top-deals-tabs');
        initTabs('buendeal-choice-tabs');
        initTabs('auth-tabs');
    }
};

export default tabs;
