import React from 'react';
import FinalFormInputWrapper from './FinalFormInputWrapper';
import TextInput from '../inputs/TextInput';

const TextInputWrapper = (props) => (
  <FinalFormInputWrapper {...props} component={TextInput} />
);

TextInputWrapper.propTypes = {};

TextInputWrapper.defaultProps = {};

export default TextInputWrapper;
