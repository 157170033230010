const profileProducts = {
    ordersStatusSelector: $('.order-status-select'),

    setOptionColor() {
        this.ordersStatusSelector.change(() => {
            let current = this.ordersStatusSelector.val();
            current === 'Paid' || current === 'Completed'
                ? this.ordersStatusSelector.css('color', '#34A853')
                : this.ordersStatusSelector.css('color', '#EA3535');
        });
    },

    init() {
        this.setOptionColor()
    }
};

export default profileProducts;
