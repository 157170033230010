import React from 'react';
import FinalFormInputWrapper from "./FinalFormInputWrapper";
import TextAreaInput from "../inputs/TextAreaInput";


const TextAreaWrapper = (props) => (
  <FinalFormInputWrapper {...props} component={TextAreaInput} />
);

TextAreaWrapper.propTypes = {};

TextAreaWrapper.defaultProps = {};

export default TextAreaWrapper;
