const mobileCatalog = {
    init: () => {
        const mobileCatalog = $('#mobile-catalog');
        const overlay = $(document.createElement('div'));
        overlay.addClass('overlay');

        $('#mobile-catalog-open').on('click', () => {
            $("body").addClass('disabled-scroll');
            $("body").append(overlay);
            setTimeout(() => {
                overlay.addClass('active');
            }, 0);

            mobileCatalog.addClass('open');

            overlay.on('click', () => {
                overlay.removeClass('active');
                mobileCatalog.removeClass('open');
                setTimeout(() => {
                    overlay.remove();
                    $("body").removeClass('disabled-scroll');
                }, 300);
            })
        });

        $('#mobile-catalog .js-close-btn').on('click', () => {
            overlay.removeClass('active');
            mobileCatalog.removeClass('open');
            setTimeout(() => {
                overlay.remove();
                $("body").removeClass('disabled-scroll');
            }, 300);
        });
    }
};

export default mobileCatalog;
