import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import intl from "../i18n/translate";
import {transformCategoryListToOptions} from "./AddProductForm/components/MainInfoSection";
import {Field, Form} from "react-final-form";
import SelectInputWrapper from "./finalFormWrappers/SelectInputWrapper";
import CheckboxWrapper from "./finalFormWrappers/CheckboxWrapper";
import useCustomEventListener from "../utils/useCustomEventListener";

const templateOptions = [
    { label: 'Aimeos', value: 'aimeos' },
    { label: 'Shopify', value: 'shopify' }
];


const ImportCsvForm = () => {
    const [currentFile, setCurrentFile] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [childSubCategoryList, setChildSubCategoryList] = useState([]);

    useEffect(() => {
        const catalogList = JSON.parse(localStorage.getItem('catalogList'));
        if (catalogList) {
            setCategoryList(transformCategoryListToOptions(catalogList));
        }
    }, []);

    useCustomEventListener(
        'SAVE_CATALOG_LIST',
        ({ detail: catalogList }) => {
            setCategoryList(transformCategoryListToOptions(catalogList));
        }
    );

    const handleFileInputChange = useCallback(({ target: { files } }) => {
        if (files.length) {
            setCurrentFile(files[0]);
        } else {
            setCurrentFile(null);
        }
    }, []);

    const handleSelectCategory = useCallback((catId) => {
        const selectedCategory = categoryList.find(({ value }) => value === catId);
        if (selectedCategory) {
            setSubCategoryList(transformCategoryListToOptions(selectedCategory.child))
        }
    }, [categoryList]);

    const handleSelectSubCategory = useCallback((catId) => {
        const selectedCategory = subCategoryList.find(({ value }) => value === catId);
        if (selectedCategory) {
            setChildSubCategoryList(transformCategoryListToOptions(selectedCategory.child));
        }
    }, [subCategoryList]);

    const submitForm = useCallback((formValues) => {
        return new Promise((resolve) => {
            const formData = new FormData();
            formData.set(`csvFile`, currentFile);

            for (const key in formValues) {
                formData.set(key, formValues[key]);
            }

            $.ajax({
                url: '/internal-api/profile/products/import',
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                success: (res) => {
                    resolve(true);
                    $('#csvImport').modal('hide');
                },
                error: (err) => {
                    resolve(err.responseJSON);
                }
            });
        });
    }, [currentFile]);

    return (
        <Form
            onSubmit={submitForm}
            render={({ values, handleSubmit, submitErrors, form }) => (
                <form
                    className="importCsvForm"
                    noValidate
                    onSubmit={(event) => {
                        const promise = handleSubmit(event);
                        promise && promise.then(() => {
                            form.reset();
                        });

                        return promise;
                    }}
                >
                    <div className="csv-upload-area mb-4 has-error">
                        <input
                            id="csvInput"
                            className="d-none"
                            type="file"
                            accept=".csv"
                            name="csvFile"
                            required
                            onChange={handleFileInputChange}
                        />
                        {!currentFile && (
                            <svg
                                className="mb-2 primary-color"
                                width="72"
                                height="72"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd" clipRule="evenodd"
                                    d="M12.75 4.00006C12.75 3.58585 12.4142 3.25006 12 3.25006C11.5858 3.25006 11.25 3.58585 11.25 4.00006V12.1894L8.53032 9.46973C8.23742 9.17684 7.76255 9.17684 7.46966 9.46973C7.17676 9.76262 7.17676 10.2375 7.46966 10.5304L11.4697 14.5304C11.6103 14.671 11.8011 14.7501 12 14.7501C12.1989 14.7501 12.3897 14.671 12.5303 14.5304L16.5303 10.5304C16.8232 10.2375 16.8232 9.76262 16.5303 9.46973C16.2374 9.17684 15.7625 9.17684 15.4697 9.46973L12.75 12.1894V4.00006ZM3.75 15.0001C3.75 14.5859 3.41421 14.2501 3 14.2501C2.58579 14.2501 2.25 14.5859 2.25 15.0001L2.25 15.5L2.25 15.5452C2.24999 16.6796 2.24997 17.5944 2.33222 18.3239C2.41726 19.0782 2.5981 19.7267 3.02497 20.283C3.22445 20.5429 3.45713 20.7756 3.71709 20.9751C4.27338 21.402 4.92192 21.5828 5.67621 21.6679C6.40572 21.7501 7.32042 21.7501 8.45486 21.7501H8.50008H15.4999H15.5451H15.5451C16.6796 21.7501 17.5943 21.7501 18.3238 21.6679C19.0781 21.5828 19.7266 21.402 20.2829 20.9751C20.5429 20.7756 20.7756 20.5429 20.975 20.283C21.4019 19.7267 21.5827 19.0782 21.6678 18.3239C21.75 17.5944 21.75 16.6797 21.75 15.5452V15.5V15.0001C21.75 14.5859 21.4142 14.2501 21 14.2501C20.5858 14.2501 20.25 14.5859 20.25 15.0001V15.5C20.25 16.6903 20.2489 17.5202 20.1772 18.1558C20.1073 18.7763 19.9781 19.1182 19.785 19.3698C19.6653 19.5258 19.5257 19.6654 19.3697 19.7851C19.1181 19.9782 18.7763 20.1074 18.1557 20.1773C17.5201 20.249 16.6902 20.2501 15.4999 20.2501H8.50008C7.30981 20.2501 6.47988 20.249 5.84425 20.1773C5.22374 20.1073 4.88189 19.9782 4.63025 19.7851C4.47428 19.6654 4.33467 19.5258 4.21498 19.3698C4.02188 19.1182 3.89273 18.7763 3.82278 18.1558C3.75112 17.5202 3.75 16.6903 3.75 15.5L3.75 15.0001Z"
                                    fill="currentColor"
                                />
                            </svg>

                        )}
                        {currentFile && (
                            <p className="mb-3">
                                <span className="font-500">{intl.translate('addProductForm.file')}:</span> <span className="text-grey">{currentFile.name}</span>
                            </p>
                        )}
                        <label htmlFor="csvInput" className="basicBtn outlined">{ currentFile ? intl.translate('addProductForm.replaceFile') : intl.translate('addProductForm.addFile')}</label>
                        {!currentFile && (
                            <p className="text-grey text-sm mt-2">{intl.translate('addProductForm.dropFiles')}</p>
                        )}
                    </div>
                    <div className="mt-1"></div>
                    {submitErrors?.csvFile && submitErrors.csvFile.map((errMsg) => (
                        <div key={errMsg} className="invalid-feedback d-block">{errMsg}</div>
                    ))}
                    {/*<div className="form-group">*/}
                    {/*    <div className="col-md-6 col-md-offset-4">*/}
                    {/*        <div className="checkbox">*/}
                    {/*            <label>*/}
                    {/*                <input type="checkbox" name="header" />*/}
                    {/*                {intl.translate('profile-product-create-import-input-header')}*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="form-group">
                        <label>{intl.translate('addProductForm.csvTemplate')}</label>
                        <Field
                            name="csvType"
                            placeholder={intl.translate('addProductForm.selectCategory')}
                            options={templateOptions}
                            onSelectValue={handleSelectCategory}
                            component={SelectInputWrapper}
                        />
                    </div>
                    <div className="form-group">
                        <label>{intl.translate('addProductForm.category')}</label>
                        <Field
                            name="category"
                            placeholder={intl.translate('addProductForm.selectCategory')}
                            options={categoryList}
                            onSelectValue={handleSelectCategory}
                            component={SelectInputWrapper}
                        />
                    </div>
                    {values.category && (
                        <div className="form-group">
                            <label>{intl.translate('addProductForm.subCategory')}</label>
                            <Field
                                name="subCategory"
                                placeholder={intl.translate('addProductForm.selectSubCategory')}
                                options={subCategoryList}
                                onSelectValue={handleSelectSubCategory}
                                component={SelectInputWrapper}
                            />
                        </div>
                    )}
                    {values.subCategory && (
                        <div className="form-group">
                            <label>
                                {intl.translate('addProductForm.childSubCategory')}
                            </label>
                            <Field
                                name="childSubCategory"
                                placeholder={intl.translate('addProductForm.selectChildSubCategory')}
                                options={childSubCategoryList}
                                component={SelectInputWrapper}
                            />
                        </div>
                    )}
                    <div className="mb-3">
                        <Field
                            className="mb-2"
                            name="overwrite"
                            type="checkbox"
                            component={CheckboxWrapper}
                            label={intl.translate('addProductForm.overwriteProducts')}
                        />
                    </div>
                    <div className="bottom-actions">
                        <button type="button" className="basicBtn outlined mr-2" data-dismiss="modal" aria-label="Close">
                            {intl.translate('addProductForm.cancel')}
                        </button>
                        <button
                            className="basicBtn"
                            disabled={
                                !currentFile
                                || !values.category
                                || !values.subCategory
                                || !values.childSubCategory
                            }
                        >
                            <svg className="mr-1" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12.75 4.00006C12.75 3.58585 12.4142 3.25006 12 3.25006C11.5858 3.25006 11.25 3.58585 11.25 4.00006V12.1894L8.53032 9.46973C8.23742 9.17684 7.76255 9.17684 7.46966 9.46973C7.17676 9.76262 7.17676 10.2375 7.46966 10.5304L11.4697 14.5304C11.6103 14.671 11.8011 14.7501 12 14.7501C12.1989 14.7501 12.3897 14.671 12.5303 14.5304L16.5303 10.5304C16.8232 10.2375 16.8232 9.76262 16.5303 9.46973C16.2374 9.17684 15.7625 9.17684 15.4697 9.46973L12.75 12.1894V4.00006ZM3.75 15.0001C3.75 14.5859 3.41421 14.2501 3 14.2501C2.58579 14.2501 2.25 14.5859 2.25 15.0001L2.25 15.5L2.25 15.5452C2.24999 16.6796 2.24997 17.5944 2.33222 18.3239C2.41726 19.0782 2.5981 19.7267 3.02497 20.283C3.22445 20.5429 3.45713 20.7756 3.71709 20.9751C4.27338 21.402 4.92192 21.5828 5.67621 21.6679C6.40572 21.7501 7.32042 21.7501 8.45486 21.7501H8.50008H15.4999H15.5451H15.5451C16.6796 21.7501 17.5943 21.7501 18.3238 21.6679C19.0781 21.5828 19.7266 21.402 20.2829 20.9751C20.5429 20.7756 20.7756 20.5429 20.975 20.283C21.4019 19.7267 21.5827 19.0782 21.6678 18.3239C21.75 17.5944 21.75 16.6797 21.75 15.5452V15.5V15.0001C21.75 14.5859 21.4142 14.2501 21 14.2501C20.5858 14.2501 20.25 14.5859 20.25 15.0001V15.5C20.25 16.6903 20.2489 17.5202 20.1772 18.1558C20.1073 18.7763 19.9781 19.1182 19.785 19.3698C19.6653 19.5258 19.5257 19.6654 19.3697 19.7851C19.1181 19.9782 18.7763 20.1074 18.1557 20.1773C17.5201 20.249 16.6902 20.2501 15.4999 20.2501H8.50008C7.30981 20.2501 6.47988 20.249 5.84425 20.1773C5.22374 20.1073 4.88189 19.9782 4.63025 19.7851C4.47428 19.6654 4.33467 19.5258 4.21498 19.3698C4.02188 19.1182 3.89273 18.7763 3.82278 18.1558C3.75112 17.5202 3.75 16.6903 3.75 15.5L3.75 15.0001Z"
                                      fill="currentColor"
                                />
                            </svg>
                            <span>{intl.translate('addProductForm.startImporting')}</span>
                        </button>
                    </div>
                </form>
            )}
        />
    )
};

if (document.getElementById('import-csv-form-placeholder')) {
    ReactDOM.render(<ImportCsvForm />, document.getElementById('import-csv-form-placeholder'));
}

export default ImportCsvForm;
