import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from "../inputs/Checkbox";



const CheckboxWrapper = ({ input, ...rest }) => (
    <Checkbox
      {...input}
      {...rest}
    />
);

CheckboxWrapper.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

export default CheckboxWrapper;
