import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";

const TextInput = ({ label, helperText = null, error, value, onChange, ...rest }) => {
    let messageList = helperText;

    if (helperText && !Array.isArray(helperText)) {
        messageList = [helperText];
    }
    return (
        <div className="form-group">
            {label && <label>{label}</label>}
            <input
                className={clsx('form-control', error && 'invalid')}
                value={value}
                onChange={onChange}
                {...rest}
            />
            {messageList?.map((item) => (
                <div
                    key={`helperText-${item}`}
                    className={clsx('pt-1 text-xs text-grey', error && 'error-color')}
                >{item}</div>
            ))}
        </div>
    );
};

export default TextInput;
