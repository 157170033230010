const welcomeModal = {
    init() {
        $('.welcome-new-user').on('scroll touchmove mousewheel', function(e){
            e.preventDefault();
            e.stopPropagation();
            return false;
        })

        $('.welcome-new-user').on('click', function (e) {
            if($(event.target).is('.welcome-new-user')) {
                $('.welcome-new-user').addClass('d-none')
                stroreViewed();
            }
        });

        $('.explore-btn, .close-modal-btn').on('click', function (e) {
            $('.welcome-new-user').addClass('d-none');
            stroreViewed();
        });


        function stroreViewed()
        {
            let cookie_string = 'welcomeViewed' + "=" + escape('true');
            cookie_string += "; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
            document.cookie = cookie_string;
        }
    }
};

export default welcomeModal;
