const desktopCatalog = {
    init: () => {
        $('.dropdown-menu').on('click', (e) => {
            e.stopPropagation();
        });

        $('.featured-products, .sales-section').find('.product-card').on({
            mouseover: function () {
                $(this).find('.product-compare-block').css({display: 'block'});
            },
            mouseout: function () {
                $(this).find('.product-compare-block').css({display: 'none'});
            }
        })

    }
};

export default desktopCatalog;
