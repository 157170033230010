const profileQuestions = {

    init() {
        $('input[type="checkbox"]', '.question-item').on('change', (e) => {
            $(e.delegateTarget).siblings('.answer-thread').fadeToggle(300);
        });

        $('.answer-btn', '.question-item').on('click', (e) => {
            const parentContainer = $(e.delegateTarget).parents('.question-item')[0];
            const questionId = $(parentContainer).data('questionId');
            console.log(questionId);
            const questionText = $(parentContainer).find('.question-text').text();
            $('#answerQuestionModal .question-container').text(questionText);
            $('#answerQuestionModal').modal('show');
        });
    }
};

export default profileQuestions;
