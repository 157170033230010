const cart = {

    init() {
        $('#cart-dropdown .closeBtn').on('click', () => {
            $('#openCartBtn').dropdown('hide');
        });

        $('.increment', '#cart-dropdown').on('click', (e) => {
            const parentContainer = $(e.delegateTarget).parents('.product-item')[0];
            const min = +$(parentContainer).data('min');
            const max = +$(parentContainer).data('max');
            const valueEl = $(parentContainer).find('.quantity-counter .value')
            const currVal = +valueEl.text();
            const canIncrement = max - currVal >= min;

            if (canIncrement) {
                const newVal = currVal + min;
                valueEl.text(newVal);
            }
        });

        $('.decrement', '#cart-dropdown').on('click', (e) => {
            const parentContainer = $(e.delegateTarget).parents('.product-item')[0];
            const min = +$(parentContainer).data('min');
            const max = +$(parentContainer).data('max');
            const valueEl = $(parentContainer).find('.quantity-counter .value')
            const currVal = +valueEl.text();
            const canDecrement = currVal - min >= min;

            if (canDecrement) {
                const newVal = currVal - min;
                valueEl.text(newVal);
            }
        });
    }
};

export default cart;
