import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SelectInput = (
    {
        className,
        helperText,
        value: valueProp,
        options,
        onChange,
        placeholder,
        error,
        onSelectValue,
        ...rest
  },
) => {
    let messageList = helperText;

    if (helperText && !Array.isArray(helperText)) {
        messageList = [helperText];
    }

    const handleChange = useCallback((e) => {
        if (onSelectValue) {
            onSelectValue(e.currentTarget.value);
        }
        onChange(e);
    }, [onChange, onSelectValue])

    return (
        <>
            <select
                className={clsx(
                    'form-control',
                    className,
                    { 'placeholderStyle': Boolean(placeholder) && valueProp === '' },
                    error && 'invalid'
                )}
                value={valueProp}
                onChange={handleChange}
                readOnly={!onChange}
                {...rest}
            >
                {Boolean(placeholder) && (
                    <option value="" disabled hidden>
                        {placeholder}
                    </option>
                )}
                {options.map(({ label, value }) => (
                    <option
                        value={value}
                        key={value}
                    >
                        {label}
                    </option>
                ))}
            </select>
            {messageList?.map((item) => (
                <div
                    key={`helperText-${item}`}
                    className={clsx('pt-1 text-xs text-grey', error && 'error-color')}
                >{item}</div>
            ))}
        </>
    );
}

SelectInput.propTypes = {
    className: PropTypes.string,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onSelectValue: PropTypes.func,
};

SelectInput.defaultProps = {
  onChange: undefined,
  options: [],
  placeholder: '',
  value: '',
    helperText: null,
    className: '',
    onSelectValue: undefined,
};

export default SelectInput;
