import DesktopCatalogReactComponent from './components/DesktopCatalog';
import MobileCatalogReactComponent from './components/MobileCatalog';
import CreateReviewForm from './components/CreateReviewForm';
import EditProductComponent from './components/EditProductComponent';
import AddProductComponent from './components/AddProductComponent';
import ImportCsvForm from './components/ImportCsvForm';
import authSidebar from "./auth/authSidebar";

import mobileCatalog from './mobileCatalog';
import createStore from "./createStore";
import desktopCatalog from './desktopCatalog';
import tabs from './tabs';
import sliders from './sliders';
import pagination from './pagination';
import priceRange from "./priceRange";
import filters from "./filters";
import emailVerification from "./emailVerification";
import productDetails from "./productDetails";
import helpCenter from './helpCenter';
import profileProducts from "./profile/profileProducts";
import profileErrors from "./profile/profileErrors";
import profileSettings from "./profile/profileSettings";
import cart from "./cart";
import profileOrders from "./profile/profileOrders";
import passwordInput from "./passwordInput";
import profileQuestions from "./profile/profileQuestions";
import customEvent from "./utils/customEvent";
import categorySwitch from "./category";
import profile from "./profile";
import {InputMask} from "./libs/input-mask";
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/mobilefriendly';
import wishlist from "./wishlist";
import contactUs from "./contactUs";
import quickEdit from "./quickEdit";
import welcomeModal from "./welcomeModal";

$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="_token"]').attr('content')
        }
    });

    $('.customInput input').on('focus', function (e) {
        $(this).parent().addClass('focused');
    });

    $('.customInput input').on('blur', function (e) {
        $(this).parent().removeClass('focused');
    });

    $('form').find('input').on('input', function () {
        $(this).removeClass('invalid');
    });

    $.get('/api/v1/category').done(({ data: {child: list} }) => {
        localStorage.setItem('catalogList', JSON.stringify(list));
        customEvent.trigger('SAVE_CATALOG_LIST', list);
    }).fail(() => {
        console.log( "error" );
    });

    authSidebar.init();
    mobileCatalog.init();
    desktopCatalog.init();
    sliders.init();
    tabs.init();
    pagination.init();
    priceRange.init();
    filters.init();
    emailVerification.init();
    createStore.init();
    productDetails.init();
    profileProducts.init();
    profileErrors.init();
    profileSettings.init();
    helpCenter.init();
    cart.init();
    profileOrders.init();
    passwordInput.init();
    profileQuestions.init();
    categorySwitch.init();
    profile.init();
    wishlist.init();
    contactUs.init();
    quickEdit.init();
    welcomeModal.init();

    new InputMask();

    const litePickerEl = document.getElementById('litepicker');

    if (litePickerEl) {
        const picker = new Litepicker({
            element: litePickerEl,
            plugins: ['mobilefriendly'],
            singleMode: false,
            // numberOfColumns: 2,
            numberOfMonths: 2,
        });
    }

    const outerChipWrapper = $('.outer-chips-wrapper');
    const chipsHeight = outerChipWrapper.find('.chips').outerHeight();
    outerChipWrapper.css('min-height', chipsHeight);

    $('#getPaidBtn').on('click', () => {
        $('#fillPaymentDetailsModal').modal('show');
    });

    $('#payout-request-form').on('submit', (e) => {
        e.preventDefault();

        // TODO: Add backend integration

        // const currentForm = $(e.delegateTarget);
        //
        // $.ajax({
        //     url: '/es/email/two-factor-authentication',
        //     data: $(this).serialize(),
        //     type: 'POST',
        //     dataType: 'json',
        //     success: function () {
        //
        //     },
        //     error: (err) => {
        //         if (err.status === 422) {
        //             const errorsObj = err.responseJSON.errors;
        //             self.showValidationErrors(currentForm, errorsObj);
        //         }
        //     }
        // });

        $('.payout-request').hide();
        $('.payout-request-accepted').show();
    });
});
