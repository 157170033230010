import intl from "../i18n/translate";

const profileErrors = {

    init() {
        $('input[type="checkbox"]', '.error-item').on('change', (e) => {
            $(e.delegateTarget).siblings('.error-thread').fadeToggle(300);
        });

        $(document).on('click', '.logs-delete-selected', function () {
            var tabAttribs = [];
            $(".select-wishlist-item:checked").each(function () {
                tabAttribs.push(parseInt($(this)[0].dataset.id, 10));
            });

            if (tabAttribs.length <= 0) {
                toastr.error(null, intl.translate('toastrMessage.wishlist-no-items-selected'));

                return false;
            }
            $('#importLogsIds').val(tabAttribs);
            $("#confirmDeleteErrorModal").modal('show');
        });

        $('.delete-error-btn').on('click',  function () {
            $('.delete-import-log-record').attr('action', $(this).attr('data-link'));
            $("#confirmDeleteLogRecordModal").modal('show');
        });

        $(document).on('change', '.select-wishlist-item', function () {
            let selectAllBtn = $('.import-logs-select-all');
            if ($('.select-wishlist-item:checked').length === $('.select-wishlist-item').length) {
                selectAllBtn.prop('disabled', true);

                return true;
            }

            selectAllBtn.prop('disabled', false);
        });

        $(document).on('click', '.import-logs-select-all', function () {
            $('.select-wishlist-item').prop('checked', true);
            $(this).prop('disabled', true);
        });
    }
};

export default profileErrors;
