import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Field, Form} from "react-final-form";
import MainInfoSection from "./components/MainInfoSection";
import ProductOptionsSection from "./components/ProductOptionsSection";
import ProductSpecsSections from "./components/ProductSpecsSections";
import SeoKeywordsInputWrapper from "../finalFormWrappers/SeoKeywordsInputWrapper";
import intl from "../../i18n/translate";

export const locales = {
    en: 'en',
    es: 'es',
};

const AddProductForm = ({ currentProduct, onDeleteOldImg, onSubmitForm }) => {
    const [images, setImages] = useState([]);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [locale, setLocale] = useState(null);
    const [productSpecs, setProductSpecs] = useState([]);
    const [availableOptions, setAvailableOptions] = useState({});

    useEffect(() => {
        const currentLocale = window.location.pathname.split('/')[1];
        setLocale(locales[currentLocale] ?? locales.es)
    }, []);

    useEffect(() => {
        if (currentProduct) {
            const preparedImageList = currentProduct.images.map(({ id, url }) => ({
                id,
                file: { name: id },
                previewUrl: url,
            }));
            setImages(preparedImageList);
        }
    }, [currentProduct]);

    const updateImageList = useCallback((list) => {
        setImages(list);
    }, []);

    const handleSetFeaturedImage = useCallback(({ currentTarget }) => {
        const { fileName } = currentTarget.dataset;
        setFeaturedImage(fileName);
    }, []);

    const handleDeleteImg = useCallback(({ currentTarget }) => {
        const { fileName } = currentTarget.dataset;
        const filteredImages = images.filter(({ file }) => fileName !== file.name);
        const currentImg = images.find(({ file }) => fileName === file.name);

        if (currentImg.hasOwnProperty('id')) {
            onDeleteOldImg(currentImg.id);
        }

        setImages(filteredImages);
    }, [images]);

    const switchLocale = useCallback(({ currentTarget }) => {
        const { locale: newLocale } = currentTarget.dataset;
        setLocale(locales[newLocale]);
    }, []);

    const fetchAttributes = useCallback((catId) => {
        $.get(`/api/v1/product/specs/${catId}`).done(({data}) => {
            setProductSpecs(data);
        }).fail(() => {
            console.log( "error" );
        });

        $.get(`/api/v1/product/options/${catId}`).done(({data}) => {
            const options = data.reduce((accum, { id, name, items }) => ({
                ...accum,
                [id]: {
                    value: id,
                    label: name,
                    items: items.map((item) => ({ value: item.id, label: item.label })),
                }
            }), {});
            setAvailableOptions(options);
        }).fail(() => {
            console.log( "error" );
        });
    }, []);

    const submitForm = useCallback((formValues) => {
        const newImages = images.filter((image) => !image.hasOwnProperty('id'));

        return onSubmitForm(newImages, featuredImage, formValues)
    }, [images, featuredImage, onSubmitForm]);

    const cancelEditForm = useCallback(() => {
        history.back();
    }, []);

    return (
        <Form
            initialValues={currentProduct}
            onSubmit={submitForm}
            render={({ values, handleSubmit }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <MainInfoSection
                        locale={locale}
                        currentProduct={currentProduct}
                        images={images}
                        featuredImage={featuredImage}
                        onSwitchLocale={switchLocale}
                        onUpdateImageList={updateImageList}
                        onSetFeaturedImage={handleSetFeaturedImage}
                        onDeleteImg={handleDeleteImg}
                        onSelectChildCategory={fetchAttributes}
                        onFetchAttributes={fetchAttributes}
                    />
                    {values.childSubCategory && Object.keys(availableOptions).length > 0 && (
                        <ProductOptionsSection
                            currentProduct={currentProduct}
                            availableOptions={availableOptions}
                        />
                    )}
                    {productSpecs.length > 0 && (
                        <Field
                            name="specs"
                            specs={productSpecs}
                            component={ProductSpecsSections}
                        />
                    )}
                    <Field
                        name="seo"
                        component={SeoKeywordsInputWrapper}
                    />
                    <div className="form-submit-block">
                        <button className="basicBtn mr-3">{intl.translate('addProductForm.publish')}</button>
                        <button type="button" className="basicBtn outlined" onClick={cancelEditForm}>{intl.translate('addProductForm.cancel')}</button>
                    </div>
                </form>
            )
        }
        />
    )
};

AddProductForm.propTypes = {
    currentProduct: PropTypes.shape({}),
    onDeleteOldImg: PropTypes.func,
    onSubmitForm: PropTypes.func.isRequired,
};

AddProductForm.defaultProps = {
    currentProduct: undefined,
    onDeleteOldImg: () => {},
}

export default AddProductForm;
