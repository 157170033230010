import React, {useCallback, useEffect, useState, useMemo} from 'react';
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import SelectInput from "../../inputs/SelectInput";
import MultiSelectInput from "../../inputs/MultiSelectInput";
import OptionItemsInputWrapper from "../../finalFormWrappers/OptionItemsInputWrapper";
import intl from "../../../i18n/translate";

const ProductOptionsSection = ({ currentProduct, availableOptions }) => {
    const [selectValue, setSelectValue] = useState('');
    const [optionItems, setOptionItems] = useState([])
    const [selectedOptionItems, setSelectedOptionItems] = useState([])
    const hasCurrentProductOptions = currentProduct && Object.values(currentProduct.options).length  && availableOptions;

    const updateOption = useCallback((value) => {
        setSelectValue(value)
        setOptionItems(availableOptions[value].items)
    }, [availableOptions])

    useEffect(() => {
        if (hasCurrentProductOptions) {
            const [[optionId, productOptions]] = Object.entries(currentProduct.options)
            const productOptionsItemIds = productOptions.map(({ id }) => id)
            const currentOptionItems = availableOptions[optionId].items.filter(({ value }) => productOptionsItemIds.includes(Number(value)))

            updateOption(optionId)
            setSelectedOptionItems(currentOptionItems)
        }
    }, [currentProduct, availableOptions])

    const changeSelectValue = useCallback(({ target: { value } }) => {
        updateOption(value)
        setSelectedOptionItems([])
    }, [updateOption])

    return (
        <>
            <h3 className="section-header">{intl.translate('addProductForm.productOptions')}</h3>
            <p className="text-grey text-sm mb-3">
                {intl.translate('addProductForm.optionsDescription')}
            </p>
            <div className="row">
                <div className="col-12 col-md-3 form-group">
                    <label>{intl.translate('addProductForm.option')}</label>
                    <SelectInput
                        options={Object.values(availableOptions)}
                        placeholder={intl.translate('addProductForm.select')}
                        value={selectValue}
                        onChange={changeSelectValue}
                    />
                </div>
                <div className="col-12 col-md-9 form-group">
                    <label>
                        {intl.translate('addProductForm.values')}
                        <span className="required-color">*</span>
                    </label>
                    <div className="flex-fill">
                        <MultiSelectInput
                            options={optionItems}
                            isDisabled={!selectValue}
                            value={selectedOptionItems}
                            onChange={setSelectedOptionItems}
                        />
                    </div>
                </div>
            </div>
            <Field
                currentProductOptions={currentProduct?.options}
                name="options"
                selectedOption={availableOptions[selectValue]}
                optionItems={selectedOptionItems}
                component={OptionItemsInputWrapper}
            />
        </>
    );
};

ProductOptionsSection.propTypes = {
    availableOptions: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

export default ProductOptionsSection;
