import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Checkbox from "../../inputs/Checkbox";
import TextInput from "../../inputs/TextInput";
import {FormSpy} from "react-final-form";
import intl from "../../../i18n/translate";

const OptionItemsInput = (
    {
        selectedOption,
        currentProductOptions,
        optionItems,
        onChange,
    }
) => {
    const [checkboxValues, setCheckboxValues] = useState({});
    const [ inputValues, setInputValues] = useState({});

    const handleCheckboxChange = useCallback(({ currentTarget }) => {
        const { checked, name } = currentTarget;
        const updatedValues = {
            ...checkboxValues,
            [name]: checked,
        };

        setCheckboxValues(updatedValues);

    }, [checkboxValues]);

    const handleInputChange = useCallback(({ currentTarget }) => {
        const { value, name } = currentTarget;
        const [optionItemId, fieldName] = name.split('.');
        const updatedValues = {
            ...inputValues,
            [optionItemId]: {
                ...inputValues[optionItemId],
                [fieldName]: value,
            }
        };

        setInputValues(updatedValues);
    }, [inputValues])

    useEffect(() => {
        if (currentProductOptions && Object.values(currentProductOptions).length) {
            const [currentOptionItems] = Object.values(currentProductOptions)
            const currentFormValues = currentOptionItems.reduce((acc, { id, ...optionItemFields}) => ({
                ...acc,
                checkboxValues: {
                    ...acc.checkboxValues,
                    [id]: true,
                },
                inputValues: {
                    ...acc.inputValues,
                    [id]: { ...optionItemFields },
                }
            }), {})

            setCheckboxValues(currentFormValues.checkboxValues)
            setInputValues(currentFormValues.inputValues)
        }
    }, [currentProductOptions])

    useEffect(() => {
        const formValue = [];
        const selectedOptionItemsMap = selectedOption?.items.reduce((acc, currVal) => ({...acc, [currVal.value]: currVal}), {});
        Object.keys(checkboxValues).forEach((optionItemId) => {
            if (checkboxValues[optionItemId] && selectedOptionItemsMap[optionItemId]) {
                const optionItem = {
                    id: optionItemId,
                    ...inputValues[optionItemId],
                };

                formValue.push(optionItem)
            }
        });
        onChange(formValue);
    }, [checkboxValues, inputValues, selectedOption])

    return selectedOption ? (
        <Fragment>
            <div className="option-items-header d-none d-md-block mb-3">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-3">
                        {selectedOption.label}
                    </div>
                    <div className="col-2">
                        {intl.translate('addProductForm.quantity')}
                    </div>
                    <div className="col-2">
                        {intl.translate('addProductForm.price')}
                    </div>
                    <div className="col-2">
                        {intl.translate('addProductForm.sku')}
                    </div>
                    <div className="col-2">
                        {intl.translate('addProductForm.discount')}
                    </div>
                </div>
            </div>
            <div className="mb-3">
                {optionItems.map(({ value: optionItemId, label: optionItemLabel }) => (
                    <div key={optionItemId} className="row">
                        <div className="col-12 col-md-4">
                            <Checkbox
                                className="option-item-toggle"
                                label={optionItemLabel}
                                name={optionItemId}
                                onChange={handleCheckboxChange}
                                checked={checkboxValues[optionItemId] ?? false}
                            />
                        </div>
                        <div className="col-12 col-md-2">
                            <TextInput
                                type="number"
                                name={`${optionItemId}.quantity`}
                                value={inputValues[optionItemId]?.quantity ?? ''}
                                onChange={handleInputChange}
                                placeholder={intl.translate('addProductForm.quantity')}
                            />
                        </div>
                        <div className="col-12 col-md-2">
                            <TextInput
                                type="number"
                                name={`${optionItemId}.price`}
                                value={inputValues[optionItemId]?.price ?? ''}
                                onChange={handleInputChange}
                                placeholder={intl.translate('addProductForm.price')}
                            />
                        </div>
                        <div className="col-12 col-md-2">
                            <TextInput
                                name={`${optionItemId}.sku`}
                                value={inputValues[optionItemId]?.sku ?? ''}
                                onChange={handleInputChange}
                                placeholder={intl.translate('addProductForm.sku')}
                            />
                        </div>
                        <div className="col-12 col-md-2">
                            <TextInput
                                name={`${optionItemId}.discount`}
                                value={inputValues[optionItemId]?.discount ?? ''}
                                onChange={handleInputChange}
                                type="number"
                                placeholder={intl.translate('addProductForm.discount')}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <FormSpy
                subscription={{ submitErrors: true }}
                render={({ submitErrors }) => submitErrors?.options
                    ? submitErrors.options.map((errMsg) => (
                        <div key={errMsg} className="invalid-feedback d-block">{errMsg}</div>
                    ))
                    : null
                }
            />
        </Fragment>
    ) : null
};

export default OptionItemsInput;
