import React, {useCallback, useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import {FormSpy} from "react-final-form";
import clsx from "clsx";
import intl from "../../../i18n/translate";

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

const SeoKeywordsSection = (
    {
        value: formValue,
        onChange,
        error,
        helperText,
    }
) => {
    let messageList = helperText;

    if (helperText && !Array.isArray(helperText)) {
        messageList = [helperText];
    }

    useEffect(() => {
        if (formValue) {
            const displayValue = formValue.map((val) => createOption(val));
            setValue(displayValue);
        }
    }, [formValue]);

    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState([]);

    const handleChange = useCallback((currentValue) => {
        onChange(currentValue.map(({ value: keyword }) => keyword));
    }, []);


    const handleInputChange = useCallback((inputValue) => {
        setInputValue(inputValue);
    }, []);

    const handleKeyDown = useCallback((event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                event.preventDefault();
                setInputValue('');
                onChange([...formValue, inputValue]);
        }
    }, [inputValue, formValue]);

    return (
        <>
            <h3 className="section-header">{intl.translate('addProductForm.seo')}</h3>
            <div className="row mb-6">
                <div className="col-12 col-md-3 mb-2">
                    <p>
                        {intl.translate('addProductForm.productKeywords')}
                        <span className="required-color">*</span>
                    </p>
                </div>
                <div className="col-12 col-md-9">
                    <CreatableSelect
                        className={error ? 'invalid' : ''}
                        classNamePrefix="react-select"
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder={intl.translate('addProductForm.keywordsPlaceholder')}
                        value={value}
                    />
                    {messageList?.map((item) => (
                        <div
                            key={`helperText-${item}`}
                            className={clsx('pt-1 text-xs text-grey', error && 'error-color')}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
};

export default SeoKeywordsSection;
