import intl from "./i18n/translate";

const productDetails = {
    init() {
        $('.catalog-detail-basket .increment').on('click', () => {
            const quantityInput = $('.product-count');
            const step = Number(quantityInput.attr('step'));
            const max = Number(quantityInput.attr('max'));
            const currVal = Number(quantityInput.val());
            const canIncrement = max - currVal >= step;

            if (canIncrement) {
                const newVal = currVal + step;
                quantityInput.val(newVal);
                $('.quantity-counter .value').text(newVal);
            }

        });

        $('.catalog-detail-basket .decrement').on('click', () => {
            const quantityInput = $('.product-count');
            const step = Number(quantityInput.attr('step'));
            const min = Number(quantityInput.attr('min'));
            const currVal = Number(quantityInput.val());
            const canDecrement = currVal - min >= step;

            if (canDecrement) {
                const newVal = currVal - step;
                quantityInput.val(newVal);
                $('.quantity-counter .value').text(newVal);
            }
        });

        $(document).on('click', '.copyLink .append', function () {
            const link = $(this).siblings('span').text();
            navigator.clipboard.writeText(link)
                .then(() => {
                    toastr.success(null, 'Copied');
                })
                .catch((err) => {
                    toastr.error(null, 'Could not copy text');
                    console.error('Async: Could not copy text: ', err);
                });
        })

        $('.helpBtn', '.review-card').on('click', (e) => {
            const parentContainer = $(e.delegateTarget).parents('.review-card')[0];
            const productId = $(parentContainer).data('productId');
            const reviewId = $(parentContainer).data('reviewId');
            const vote = $(e.delegateTarget).data('vote');

            $.ajax({
                url: `/internal-api/products/${productId}/reviews/${reviewId}/votes`,
                data: { vote },
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    const count = +$(e.delegateTarget).find('.vote-count').text() + 1;
                    $(e.delegateTarget).find('.vote-count').text(count);
                    toastr.success(null, intl.translate('addProductVote.success'));
                },
                error: (err) => {
                    if (err.status === 401) {
                        toastr.error(null, intl.translate('toastrMessage.unauthorized'));
                    } else {
                        toastr.success(null, intl.translate('addProductVote.error'));
                        //toastr.error(null, err.responseJSON)
                    }
                }
            });
        });

        const descriptionText = $('.descr-text')
        const descriptionWrapper = $('.descr-wrapper')
        const seeMoreBtn = $('.see-more--btn')

        if (descriptionText.height() > 65) {
            if (window.location.pathname.split('/')[1] === 'es') {
                seeMoreBtn.text('Ver más')
            }
            else {
                seeMoreBtn.text('See more')
            }
            seeMoreBtn.addClass('active active--down')
            descriptionWrapper.addClass('small')
            descriptionText.addClass('small')
            seeMoreBtn.append('<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z" fill="currentColor"/>\n' +
                '</svg>')
        }

        seeMoreBtn.click(function() {
            if (descriptionText.height() > 65) {
                descriptionWrapper.animate(
                    { height: 65},
                    {
                        duration: 300
                    }
                );
                if (window.location.pathname.split('/')[1] === 'es') {
                    seeMoreBtn.text('Ver más')
                }
                else {
                    seeMoreBtn.text('See more')
                }
                descriptionText.toggleClass('small')
                seeMoreBtn.append('<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z" fill="currentColor"/>\n' +
                    '</svg>')
            } else {
                if (window.location.pathname.split('/')[1] === 'es') {
                    seeMoreBtn.text('Ver menos')
                }
                else {
                    seeMoreBtn.text('See less')
                }
                descriptionText.toggleClass('small')
                descriptionWrapper.animate(
                    { height: descriptionText[0].scrollHeight + 15 },
                    {
                        duration: 300
                    }
                );
                seeMoreBtn.append('<svg style="transform: scale(1, -1)" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12 13.9393L17.4697 8.46967C17.7626 8.17678 18.2374 8.17678 18.5303 8.46967C18.8232 8.76256 18.8232 9.23744 18.5303 9.53033L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967Z" fill="currentColor"/>\n' +
                    '</svg>')
            }
        })
    }
}

export default productDetails;
