import React, {Fragment, useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import useCustomEventListener from "../utils/useCustomEventListener";

const DesktopCatalog = () => {
    const [catalogData, setCatalogData] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        const catalogList = JSON.parse(localStorage.getItem('catalogList'));
        if (catalogList) {
            setCatalogData(catalogList);
            setActiveCategory(catalogList[0]);
        }
    }, []);

    useCustomEventListener(
        'SAVE_CATALOG_LIST',
        ({ detail: catalogList }) => {
            setCatalogData(catalogList);
            setActiveCategory(catalogList[0]);
        }
    );

    const activateCategory = useCallback(({ currentTarget }) => {
        const { id } = currentTarget.dataset;
        const currentCategory = catalogData.find((category) => category.id === id);
        setActiveCategory(currentCategory);
    }, [catalogData]);

    return (
        <div className="d-flex">
            <div className="category-list mr-5">
                {catalogData.map(({ id, label, target }) => (
                    <div
                        key={id}
                        className="catalog-category-heading"
                        data-id={id}
                        onMouseEnter={activateCategory}
                    >
                        <button className="collapse-btn" type="button">
                            <a href={target}><span className="text-ellipsis-nowrap">{label}</span></a>
                            <svg className="chevron" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
                                      fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
            <div className="flex-fill overflow-hidden">
                <h2 className="current-category-label">{activeCategory?.label}</h2>
                <div className="catalog-category-body">
                    {activeCategory?.child?.map(({ id, label, target, child: subChildren }) => (
                        <Fragment key={id}>
                            <a href={target} className="subcategory-link text-ellipsis-nowrap">{label}</a>
                            {subChildren && subChildren.map(({ id: subChildId, label: subChildLabel, target  }) => (
                                <a key={subChildId} href={target} className="subcategory-link subcategory-link-child text-ellipsis-nowrap">{subChildLabel}</a>
                            ))}
                        </Fragment>
                    ))}
                    <a href="/" className="category-banner">
                        <img src="/img/category-banner.png" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default DesktopCatalog;

if (document.getElementById('desktop-catalog')) {
    ReactDOM.render(<DesktopCatalog />, document.getElementById('desktop-catalog'));
}
