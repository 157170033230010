import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ className = '', name, label, onChange, checked, ...rest }) => (
    <label className={`customCheckbox ${className}`}>
        <input
            type="checkbox"
            name={name}
            checked={checked}
            onChange={onChange}
            {...rest}
        />
        <div className="checkbox">
            <svg className="checked-icon" width="14" height="14" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.84 3.44714C14.1453 3.72703 14.1659 4.20146 13.886 4.5068L6.5527 12.5068C6.41447 12.6576 6.22065 12.7454 6.01613 12.7498C5.81161 12.7543 5.61416 12.675 5.46951 12.5303L2.13617 9.197C1.84328 8.90411 1.84328 8.42923 2.13617 8.13634C2.42907 7.84345 2.90394 7.84345 3.19683 8.13634L5.97628 10.9158L12.7803 3.49321C13.0602 3.18787 13.5346 3.16724 13.84 3.44714Z"
                      fill="currentColor"/>
            </svg>
        </div>
        <span className="label font-500">{label}</span>
    </label>
);

Checkbox.propTypes = {
    className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default Checkbox;
