import es from "./es";
import en from "./en";

const intl = {
    locales: {
        en,
        es,
    },
    translate(key) {
        const currentLocale = window.location.pathname.split('/')[1];
        if (this.locales[currentLocale]) {
            return this.locales[currentLocale][key] ?? key
        }
        return this.locales.es[key] ?? key;
    },
}

export default intl;
