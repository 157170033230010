const initSlider = (elementId, settings) => {
    const slider = $(`#${elementId} .slider`);
    const leftArrowControl = $(`#${elementId} .slider-arrow-control.left`);
    const rightArrowControl = $(`#${elementId} .slider-arrow-control.right`);

    slider.slick(settings);
    slider.siblings('.bottom-controls-placeholder').hide();
    slider.removeClass('not-inited');

    leftArrowControl.on('click', function() {
        slider.slick('slickPrev');
    });

    rightArrowControl.on('click', function() {
        slider.slick('slickNext');
    });
};

const initDepartmentSlider = () => {
    const elementId = 'department-slider';
    const settings = {
        arrows: false,
        infinite: false,
        dots: true,
        mobileFirst: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        customPaging: () => {
            return '<div class="slider-bottom-control"></div>'
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    slidesToShow: 6,
                }
            }
        ]
    };

    initSlider(elementId, settings);
};

const initMainBannerSlider = () => {
    const elementId = 'main-banner-slider';
    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        mobileFirst: true,
        dots: true,
        lazyLoad: 'progressive',
        customPaging: () => {
            return '<div class="slider-bottom-control"></div>'
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                }
            }
        ]
    };
    initSlider(elementId, settings);
};

const initLogosSlider = () => {
    const elementId = 'logos-slider';
    const settings = {
        // autoplay: true,
        // autoplaySpeed: 1000,
        arrows: false,
        infinite: false,
        mobileFirst: true,
        dots: false,
        customPaging: () => {
            return '<div class="slider-bottom-control"></div>'
        },
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 4,
                    dots: true,
                }
            }
        ]
    };
    initSlider(elementId, settings);
}

const sliders = {
    init: () => {
        initMainBannerSlider();
        initDepartmentSlider();
        initLogosSlider();
    }
}

export default sliders;
