const quickEdit = {
    init() {
        $(document).on('click', '.quickEdit-btn', function () {
            $("#quickEdit-form").modal('show');
        });

        // $(document).on('click', '.submitQuickEdit', function () {
        //     let price = $('#quickEditPrice').val(),
        //         discount = $('#quickEditDiscound').val(),
        //         locale = window.location.pathname.split('/')[1],
        //         url = $('.fn-quick-edit-form').attr('action');
        //
        //     $.ajax({
        //         url: url,
        //         data: {
        //             locale: locale,
        //             price: price,
        //             discount: discount
        //         },
        //         type: 'POST',
        //         dataType: 'json',
        //         success: function (data) {
        //             toastr.success(
        //                 null,
        //                 `${data.success.text} <a class="nav-link" href="${data.success.link}">${data.success.linkText}</a>`
        //             );
        //         },
        //         error: (err) => {
        //             console.log(err)
        //         }
        //     });
        // });
    }
};

export default quickEdit;
