const categorySwitch = {
    init() {
        let element = document.getElementById("category-see-all-btn");
        if (element) {
            element.onclick = function () {
                let tab = document.querySelector('#category-top-deals-tabs .active');
                let link = tab.getAttribute('data-route');
                element.setAttribute('href', link);
            };
        }

        let ourChoice = document.getElementById("our-choice-see-all-btn");

        if (ourChoice) {
            ourChoice.onclick = function () {
                let choicetab = document.querySelector('#buendeal-choice-tabs .active');
                let link = choicetab.getAttribute('data-route');
                ourChoice.setAttribute('href', link);
            };
        }

        // $('.categories-list li').on('click', function (event) {
        //     event.stopPropagation();
        //
        //     if (event.target.tagName.toLowerCase() === 'a') {
        //         $(event.target.parentNode.parentNode)
        //             .find('span')
        //             .removeClass('selected');
        //
        //         $(event.target).addClass('selected');
        //     }
        // });
    }
};

export default categorySwitch;
