import React, {useCallback} from 'react';
import ReactDOM from 'react-dom';
import AddProductForm from "./AddProductForm/AddProductForm";

const AddProductComponent = () => {
    const createProduct = useCallback((images, featuredImage, formValues) => {
        const formData = new FormData();

        if (images.length > 0 ) {
            images.forEach(({ file }, index) => {
                formData.set(`image[${index}]`, file);
            });
        }
        formData.set('featuredImage', featuredImage);
        formData.set('formValues', JSON.stringify(formValues));

        return new Promise((resolve) => {
            $.ajax({
                url: '/en/profile/seller/products',
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                success: (res) => {
                    resolve(true);
                    window.location.href = window.location.href.split('/create')[0];
                },
                error: (err) => {
                    resolve(err.responseJSON);
                }
            });
        });
    }, []);

    return (
        <AddProductForm onSubmitForm={createProduct} />
    )
};

if (document.getElementById('add-product-form-placeholder')) {
    ReactDOM.render(<AddProductComponent />, document.getElementById('add-product-form-placeholder'));
}

export default AddProductComponent;
