import React, {Fragment, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import useCustomEventListener from "../utils/useCustomEventListener";

const MobileCatalog = () => {
    const [catalogData, setCatalogData] = useState([]);

    useEffect(() => {
        const catalogList = JSON.parse(localStorage.getItem('catalogList'));
        if (catalogList) {
            setCatalogData(catalogList);
        }
    }, []);

    useCustomEventListener(
        'SAVE_CATALOG_LIST',
        ({ detail: catalogList }) => {
            setCatalogData(catalogList);
        }
    );

    return (
        <div className="accordion" id="mobileCatalog">
            {catalogData.map(({ id, label, target, child }) => (
                <div key={id} className="category-item">
                    <div className="catalog-category-heading" id={`heading-${id}`}>
                        <button className="collapse-btn" type="button" data-toggle="collapse" data-target={`#collapse-${id}`}
                                aria-expanded="false" aria-controls={`collapse-${id}`}>
                            <span className="text-ellipsis-nowrap">{label}</span>
                            <svg className="chevron" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
                                      fill="currentColor"/>
                            </svg>
                        </button>
                    </div>

                    <div id={`collapse-${id}`} className="collapse" aria-labelledby={`heading-${id}`} data-parent="#mobileCatalog">
                        <div className="catalog-category-body">
                            {child?.map(({ id: childId, label: childLabel, target: target, child: subChildren }) => (
                                <Fragment key={childId}>
                                    <a href={target} className="subcategory-link text-ellipsis-nowrap">{childLabel}</a>
                                    {subChildren && subChildren.map(({ id: subChildId, label: subChildLabel, target: target }) => (
                                        <a key={subChildId} href={target} className="subcategory-link subcategory-link-child text-ellipsis-nowrap">{subChildLabel}</a>
                                    ))}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MobileCatalog;

if (document.getElementById('mobileCatalogPlaceholder')) {
    ReactDOM.render(<MobileCatalog />, document.getElementById('mobileCatalogPlaceholder'));
}
