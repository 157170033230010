import React, {useCallback, useEffect, useState, useMemo} from 'react';
import PropTypes from "prop-types";
import clsx from "clsx";
import {Field, FormSpy} from "react-final-form";
import ProductImagesUpload from "./ProductImagesUpload";
import CheckboxWrapper from "../../finalFormWrappers/CheckboxWrapper";
import TextInputWrapper from "../../finalFormWrappers/TextInputWrapper";
import SelectInputWrapper from "../../finalFormWrappers/SelectInputWrapper";
import TextAreaWrapper from "../../finalFormWrappers/TextAreaWrapper";
import {locales} from "../AddProductForm";
import intl from "../../../i18n/translate";
import useCustomEventListener from "../../../utils/useCustomEventListener";

export const transformCategoryListToOptions = (list) => list.map(({ id, label, child }) => ({
    value: id,
    label,
    child
}));

const MainInfoSection = (
    {
        locale,
        currentProduct,
        images,
        featuredImage,
        onSwitchLocale,
        onUpdateImageList,
        onSetFeaturedImage,
        onDeleteImg,
        onSelectChildCategory,
    }
) => {
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [childSubCategoryList, setChildSubCategoryList] = useState([]);

    const handleSelectCategory = useCallback((catId) => {
        const selectedCategory = categoryList.find(({ value }) => value === catId);
        if (selectedCategory && selectedCategory.child) {
            setSubCategoryList(transformCategoryListToOptions(selectedCategory.child))
        }
    }, [categoryList]);

    const handleSelectSubCategory = useCallback((catId) => {
        const selectedCategory = subCategoryList.find(({ value }) => value === catId);
        if (selectedCategory && selectedCategory.child) {
            setChildSubCategoryList(transformCategoryListToOptions(selectedCategory.child));
        }
    }, [subCategoryList]);

    useCustomEventListener(
        'SAVE_CATALOG_LIST',
        ({ detail: catalogList }) => {
            setCategoryList(transformCategoryListToOptions(catalogList));
        }
    );

    useEffect(() => {
        if (currentProduct && categoryList.length) {
            const { category } = currentProduct;
            handleSelectCategory(String(category));
        }
    }, [currentProduct, categoryList]);

    useEffect(() => {
        if (currentProduct && subCategoryList.length) {
            const { subCategory } = currentProduct;
            handleSelectSubCategory(String(subCategory));
        }
    }, [currentProduct, subCategoryList]);

    useEffect(() => {
        if (currentProduct && childSubCategoryList.length) {
            const { childSubCategory } = currentProduct;
            onSelectChildCategory(childSubCategory);
        }
    }, [currentProduct, childSubCategoryList]);

    const onSelectValue = useCallback((value) => {
        onSelectChildCategory(value);
    }, [])

    return (
        <FormSpy
            subscription={{ values: true, submitErrors: true }}
            render={({ values, submitErrors }) => (
                <>
                    <h3 className="section-header">{intl.translate('addProductForm.mainInfo')}</h3>
                    <div className="row mb-3">
                        <div className="col-12 col-md-3 mb-2">
                            <p>
                                {intl.translate('addProductForm.productImages')}
                                <span className="required-color">*</span>
                            </p>
                        </div>
                        <div className="col-12 col-md-9">
                            <ProductImagesUpload
                                maxFiles={12}
                                images={images}
                                featuredImage={featuredImage}
                                onUpdateImages={onUpdateImageList}
                                onSetFeaturedImage={onSetFeaturedImage}
                                onDeleteImg={onDeleteImg}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-3 mb-2">
                            <p>
                                {intl.translate('addProductForm.languages')}
                                <span className="required-color">*</span>
                            </p>
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="auto-translate-wrapper">
                                <div className="language-switcher">
                                    <button
                                        type="button"
                                        className={clsx('switch-item', { 'active': locale === locales.es })}
                                        data-locale={locales.es}
                                        onClick={onSwitchLocale}
                                    >
                                        {locales.es}
                                    </button>
                                    <button
                                        type="button"
                                        className={clsx('switch-item', { 'active': locale === locales.en })}
                                        data-locale={locales.en}
                                        onClick={onSwitchLocale}
                                    >
                                        {locales.en}
                                    </button>
                                </div>
                                <div className="auto-translate-checkbox">
                                    <Field
                                        className="mb-2"
                                        name="autoTranslate"
                                        type="checkbox"
                                        component={CheckboxWrapper}
                                        label={intl.translate('addProductForm.autoTranslate')}
                                    />
                                    <p className="text-sm text-grey">
                                        {intl.translate('addProductForm.autoTranslateDescription')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 mb-2">
                            <label>
                                {intl.translate('addProductForm.productName')}
                                <span className="required-color">*</span>
                            </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="row">
                                <div className="col-12 col-md-7">
                                    <Field
                                        type="text"
                                        name={`name.${locale}`}
                                        component={TextInputWrapper}
                                        error={submitErrors?.name}
                                        helperText={submitErrors?.name}
                                    />
                                </div>
                                <div className="col-12 col-md-5 d-flex flex-column flex-md-row">
                                    <p className="mr-1 mb-2">
                                        {intl.translate('addProductForm.sku')}
                                        <span className="required-color">*</span>
                                    </p>
                                    <Field
                                        type="text"
                                        name="sku"
                                        component={TextInputWrapper}
                                    />
                                    <div style={{display: 'none'}}>
                                        <Field
                                            type="text"
                                            name="featuredImageName"
                                            id="featuredImageName"
                                            component={TextInputWrapper}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 mb-2">
                            <label>
                                {intl.translate('addProductForm.sellerPageLabel')}
                                <span className="required-color">*</span>
                            </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <Field
                                name="sellerUrl"
                                type="url"
                                pattern="https://.*"
                                placeholder={intl.translate('addProductForm.sellerPagePlaceholder')}
                                component={TextInputWrapper}
                                error={submitErrors?.sellerUrl}
                                helperText={submitErrors?.sellerUrl}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-3 mb-2">
                            <label>
                                {intl.translate('addProductForm.category')}
                                <span className="required-color">*</span>
                            </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <Field
                                name="category"
                                placeholder={intl.translate('addProductForm.selectCategory')}
                                options={categoryList}
                                onSelectValue={handleSelectCategory}
                                component={SelectInputWrapper}
                            />
                        </div>
                    </div>
                    {values.category && (
                        <div className="row mb-3">
                            <div className="col-12 col-md-3 mb-2">
                                <label>
                                    {intl.translate('addProductForm.subCategory')}
                                    <span className="required-color">*</span>
                                </label>
                            </div>
                            <div className="col-12 col-md-9">
                                <Field
                                    name="subCategory"
                                    placeholder={intl.translate('addProductForm.selectSubCategory')}
                                    options={subCategoryList}
                                    onSelectValue={handleSelectSubCategory}
                                    component={SelectInputWrapper}
                                />
                            </div>
                        </div>
                    )}
                    {values.subCategory && (
                        <div className="row mb-3">
                            <div className="col-12 col-md-3 mb-2">
                                <label>
                                    {intl.translate('addProductForm.childSubCategory')}
                                    <span className="required-color">*</span>
                                </label>
                            </div>
                            <div className="col-12 col-md-9">
                                <Field
                                    name="childSubCategory"
                                    placeholder={intl.translate('addProductForm.selectChildSubCategory')}
                                    options={childSubCategoryList}
                                    onSelectValue={onSelectValue}
                                    component={SelectInputWrapper}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12 col-md-3 mb-2">
                            <label>
                                {intl.translate('addProductForm.description')}
                                <span className="required-color">*</span>
                            </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <Field
                                name={`description.${locale}`}
                                placeholder={intl.translate('addProductForm.descriptionPlaceholder')}
                                component={TextAreaWrapper}
                                error={submitErrors?.[`description.${locale}`]}
                                helperText={submitErrors?.[`description.${locale}`]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 mb-2">
                            <label>
                                {intl.translate('addProductForm.price')}
                                <span className="required-color">*</span>
                            </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <Field
                                        name="price"
                                        type="number"
                                        component={TextInputWrapper}
                                    />
                                </div>
                                <div className="col-12 col-md-1"></div>
                                <div className="col-12 col-md-2 mb-2">
                                    <label>
                                        {intl.translate('addProductForm.discount')}
                                    </label>
                                </div>
                                <div className="col-12 col-md-5">
                                    <Field
                                        name="rebate"
                                        type="number"
                                        placeholder={intl.translate('addProductForm.discountPlaceholder')}
                                        component={TextInputWrapper}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-md-3 mb-2">
                            <label>
                                {intl.translate('addProductForm.quantity')}
                                <span className="required-color">*</span>
                            </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <Field
                                name="quantity"
                                type="number"
                                component={TextInputWrapper}
                            />
                        </div>
                    </div>
                </>
            )}
        />
    )
};

MainInfoSection.propTypes = {
    locale: PropTypes.string,
    currentProduct: PropTypes.shape({}),
    images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    featuredImage: PropTypes.string,
    onSwitchLocale: PropTypes.func.isRequired,
    onUpdateImageList: PropTypes.func.isRequired,
    onSelectChildCategory: PropTypes.func.isRequired,
    onDeleteImg: PropTypes.func.isRequired,
    onSetFeaturedImage: PropTypes.func.isRequired,
}

export default MainInfoSection;
