const profileProducts = {
    bottomBar: $('.mobile-bottom-bar'),
    overlay: $(document.createElement('div')).addClass('overlay'),

    openBottomBar() {
        const closeBottomBar = this.closeBottomBar.bind(this);

        $("body").addClass('disabled-scroll');
        $("body").append(this.overlay);

        setTimeout(() => {
            this.overlay.addClass('active');
        }, 0);

        this.bottomBar.addClass('open');
        this.overlay.on('click', closeBottomBar);
    },

    closeBottomBar() {
        this.overlay.removeClass('active');
        this.bottomBar.removeClass('open');
        setTimeout(() => {
            this.overlay.remove();
            $("body").removeClass('disabled-scroll');
        }, 300);
    },

    setDeleteProductModalData(clickedEl) {
        const parentContainer = $(clickedEl).parents('.product-item')[0];
        const productName = $(parentContainer).data('productName');
        const productImageUrl = $(parentContainer).data('productImageUrl');
        const deleteUrl = $(parentContainer).data('deleteUrl');

        $('#deleteProductForm').attr('action', deleteUrl);
        $("#deleteProductForm").attr("method", "post");
        $('#deleteProductModal .product-info--photo').attr('src', productImageUrl);
        $('#deleteProductModal .product-info--name').text(productName);
    },

    setEditProductLink(clickedEl) {
        const parentContainer = $(clickedEl).parents('.product-item')[0];
        const editUrl = $(parentContainer).data('editUrl');

        $('#editProductLink').attr('href', editUrl);
    },

    init() {
        $('.custom-multiselect').each(function () {
            const currentSelectedCount = $('select', this).val().length;
            $('.selected-count', this).text(currentSelectedCount);

            $('select', this).val().forEach((currVal) => {
                $(`input[value=${currVal}]`, this).prop('checked', true);
            });
        });

        $('.products-filters-btn').on('click', () => {
            $(".profile-products-filters").slideToggle();
        });

        $('.custom-multiselect input[type=checkbox]').on('change', function ({ target: { value, checked } }) {
            const parentContainer = $(this).parents('.custom-multiselect')[0];
            $(`select option[value=${value}]`, parentContainer)[0].selected = checked;

            const selectedOptionsCount = $('select', parentContainer).val().length;
            $('.selected-count', parentContainer).text(selectedOptionsCount);
        });

        $('.product-item .deleteBtn').on('click', (e) => {
            this.setDeleteProductModalData(e.delegateTarget);
            $('#deleteProductModal').modal('show');


        });

        $('.mobile-actions .iconBtn').on('click', (e) => {
            this.setDeleteProductModalData(e.delegateTarget);
            this.setEditProductLink(e.delegateTarget);
            this.openBottomBar();
        });

        $('.mobile-bottom-bar .deleteBtn').on('click', () => {
            this.closeBottomBar();
            $('#deleteProductModal').modal('show');
        });

        $('#deleteProductModal .confirmDelete').on('click', () => {
            $('#deleteProductForm').submit();
        });

        $('#profileSearchProductForm').each(function() {
            //click on enter
            $(this).find('input').keypress(function(e) {
                if(e.which == 10 || e.which == 13) {
                    this.form.submit();
                }
            });
        });

        $('#profileSortSelect').on('change', function() {
            this.form.submit();
        });

        $(document).on('click', '.featuredIcon', function () {
            let fileName = $(this).data('file-name'),
                input = document.getElementById('featuredImageName'),
                nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
            nativeInputValueSetter.call(input, fileName);
            var ev2 = new Event('change', { bubbles: true});
            input.dispatchEvent(ev2);
        });
    }
};

export default profileProducts;
