import React, {useCallback} from 'react';
import SelectInput from "../../inputs/SelectInput";
import intl from "../../../i18n/translate";

const transformSpecItemsToOptions = (items) => items.map(({ id, label }) => ({
    value: id,
    label,
}))

const ProductSpecsSections = ({ specs, input: { value, onChange } }) => {
    const changeSelectValue = useCallback(({ target: { value: specItemId, dataset } }) => {
        const { specId } = dataset;
        const updatedValue = {
            ...value,
            [specId]: specItemId,
        };

        onChange(updatedValue);

    }, [value, onChange]);

    return (
        <>
            <h3 className="section-header">{intl.translate('addProductForm.productSpecs')}</h3>
            <p className="text-grey text-sm mb-3">
                {intl.translate('addProductForm.specsDescription1')}
                <br/>
                {intl.translate('addProductForm.specsDescription2')}
                {' '}
                <a className="primary-color contact-us-product" href="#contact-us">{intl.translate('addProductForm.contactUs')}</a>
            </p>
            {specs.map(({ id, name, items }) => (
                <div key={id} className="row mb-3">
                    <div className="col-12 col-md-3 mb-1">
                        <label>{name}</label>
                    </div>
                    <div className="col-12 col-md-9">
                        <SelectInput
                            options={transformSpecItemsToOptions(items)}
                            placeholder={intl.translate('addProductForm.select')}
                            value={value[id]}
                            onChange={changeSelectValue}
                            data-spec-id={id}
                        />
                    </div>
                </div>
            ))}
        </>
    )
};

export default ProductSpecsSections;
