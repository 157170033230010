import intl from "../i18n/translate";

const emailPattern = /.+@.+..+/;

const forgotPasswordValidator = ({ email }) => {
    const errors = {};

    if (!email) {
        if (!email) {
            errors.email = [intl.translate('validationError.required')];
        } else if (!emailPattern.test(email)) {
            errors.email = [intl.translate('validationError.emailInvalid')];
        }
    }

    return errors;
};

export default forgotPasswordValidator;
