const filters = {
    init() {
        const mobileFilters = $('#mobile-filters-sidebar');

        $('#mobile-filters-open').on('click', () => {
            $("body").addClass('disabled-scroll');
            mobileFilters.addClass('open');
        });

        $('#mobile-filters-sidebar .js-close-btn').on('click', () => {
            mobileFilters.removeClass('open');
            setTimeout(() => {
                $("body").removeClass('disabled-scroll');
            }, 300);
        });

        $('.filter-chip').on('click', '.js-remove-filter', function() {
            const { attrId } = $(this).parent()[0].dataset;
            const input = $(`.catalog-list .main-form input[value=${attrId}]`);

            input.prop("checked") ? input.prop("checked", false) : input.prop("checked", true);

            $(this).parents(".catalog-list form").submit();
            $(".catalog-list").fadeTo(1000, 0.5);
        });

        $('#sort-select').on('change', function() {
            $('#filters-form').submit()
        })
    }
};

export default filters;
