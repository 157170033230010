const pagination = {
    buildPagination(currentPage, totalPagesCount) {
        const minWidthMd = window.matchMedia('screen and (min-width: 768px)').matches;

        const currentPageEl = $(`.pagination-item[data-page=${currentPage}]`);
        const prevPageEl = currentPageEl.prev('.pagination-item');
        const nextPageEl = currentPageEl.next('.pagination-item');

        const dotsPlaceholderStart = $('.dots-placeholder-start');
        const dotsPlaceholderEnd = $('.dots-placeholder-end');

        currentPageEl.addClass('active').show();

        if (currentPage === 1) {
            $('.pagination-item[data-page=2]').show();

            if (totalPagesCount > 3) {
                dotsPlaceholderEnd.show();
            }
        }

        else if (currentPage === totalPagesCount) {
            $(`.pagination-item[data-page=${totalPagesCount - 1}]`).show();

            if (totalPagesCount > 3) {
                dotsPlaceholderStart.show();
            }
        }

        else {
            const prevItemIsDotsPlaceholderStart = prevPageEl.hasClass('dots-placeholder-start');
            const nextItemIsDotsPlaceholderEnd = nextPageEl.hasClass('dots-placeholder-end');

            if (!minWidthMd) {
                if (!prevItemIsDotsPlaceholderStart) {
                    dotsPlaceholderStart.show();
                }
                if (!nextItemIsDotsPlaceholderEnd) {
                    dotsPlaceholderEnd.show();
                }
            }

            else {
                const doublePrevEl = $(prevPageEl).prev('.pagination-item');
                const doubleNextEl = $(nextPageEl).next('.pagination-item');

                if (!prevItemIsDotsPlaceholderStart) {
                    prevPageEl.show();

                    if (!doublePrevEl.hasClass('dots-placeholder-start')) {
                        dotsPlaceholderStart.show();
                    }
                }

                if (!nextItemIsDotsPlaceholderEnd) {
                    nextPageEl.show();

                    if (!doubleNextEl.hasClass('dots-placeholder-end')) {
                        dotsPlaceholderEnd.show();
                    }
                }
            }
        }
    },

    init() {
        const paginationPanel = $('.pagination-panel');

        if (paginationPanel.length) {
            const totalPagesCount = paginationPanel.data('pages');

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const currentPage = +(urlParams.get('l_page') ?? 1);

            this.buildPagination(currentPage, totalPagesCount);

            paginationPanel.show();
        }

        const customPaginationPanel = $('.custom-pagination-panel');

        if (customPaginationPanel.length) {
            const totalPagesCount = customPaginationPanel.data('pages');

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const currentPage = +(urlParams.get('offset') ?? '1');
            this.buildPagination(currentPage, totalPagesCount);

            customPaginationPanel.show();
        }
    }
};

export default pagination;
