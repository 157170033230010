const helpCenter = {
    contactSupportModal: $('#contact-support-modal'),
    overlay: $(document.createElement('div')).addClass('overlay'),
    returnLinks: $('.return-link'),
    sidebar: $('.help-center-sidebar-wrapper'),
    articles: $('.help-center-article'),

    openContactModal() {
        const closeModal = this.closeContactModal.bind(this);

        $("body").addClass('disabled-scroll');
        $("body").append(this.overlay);

        setTimeout(() => {
            this.overlay.addClass('active');
        }, 0);

        this.contactSupportModal.addClass('open');
        this.overlay.on('click', closeModal);
    },

    closeContactModal() {
        this.overlay.removeClass('active');
        this.contactSupportModal.removeClass('open');
        setTimeout(() => {
            this.overlay.remove();
            $("body").removeClass('disabled-scroll');
        }, 300);
    },

    showSidebar() {
        this.sidebar.addClass('active')
        this.articles.addClass('hidden')
    },

    init() {
        const openContactModal = this.openContactModal.bind(this);
        const closeContactModal = this.closeContactModal.bind(this);
        const returnLinks = this.returnLinks.bind(this);
        const showSidebar = this.showSidebar.bind(this);
        $(document).on('click', '.open-support-modal, .contact-us-product', openContactModal);
        $('#contact-support-modal .closeBtn').on('click', closeContactModal);
        returnLinks.on('click', showSidebar);
    }
}


export default helpCenter
