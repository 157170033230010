const profile = {
    init() {
        let form = $("#profile_settings_form");

        form.on('change', ':input' ,function () {
            form.attr("changed",true);
        });

        $('.resetBtn').on('click', function () {

            if (form.attr("changed") === "true") {
                $('#confirmReset').modal('show');
            } else {
                $('#nothingChanged').modal('show');
            }

            $("#reset_settings_btn").on('click', function () {
                document.getElementById("profile_settings_form").reset();
                form.attr("changed", false);
            });
        });
    }
};

export default profile;
