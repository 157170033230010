import createStore from "../createStore";

const profileSettings = {
    updatePreviewUrl(url) {
        $('.profile-settings .img-preview').attr('src', url);
    },

    readURL(input) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.updatePreviewUrl(e.target.result)
            }

            reader.readAsDataURL(input.files[0]);
        }
    },
    init() {
        const self = this;
        const avatarInput = $('#avatar-input');
        const currentAvatarUrl = $('.profile-settings .img-preview').attr('src');

        avatarInput.bind('change', function () {
            if (this.files.length) {
                if (this.files[0].size / 1024 / 1024 > 2) {
                    $(this).val('');
                    $('.max-size-hint').addClass('invalid');
                    self.updatePreviewUrl(currentAvatarUrl);
                    return;
                }
                $('.max-size-hint').removeClass('invalid');
                self.readURL(this);
            } else {
                $('.max-size-hint').removeClass('invalid');
                self.updatePreviewUrl(currentAvatarUrl);
            }
        });

        $('input[name="toggle_company"]', '#payment-settings-form').on('change', ({ target }) => {
            console.log(target.checked);
            const companyNameInputEl = $('input[name="company_name"]', '#payment-settings-form');
            const businessAddressBlockEl = $('#business-address-block', '#payment-settings-form');

            companyNameInputEl.prop('disabled', !target.checked);

            target.checked ? businessAddressBlockEl.show() : businessAddressBlockEl.hide();
        });
    }
};

export default profileSettings;
