import React from 'react';
import FinalFormInputWrapper from "./FinalFormInputWrapper";
import SelectInput from "../inputs/SelectInput";

const SelectInputWrapper = (props) => (
  <FinalFormInputWrapper
    {...props}
    component={SelectInput}
  />
);

SelectInputWrapper.propTypes = {};

SelectInputWrapper.defaultProps = {};

export default SelectInputWrapper;
