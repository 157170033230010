import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";

const TextAreaInput = (
    {
        className,
        label,
        rows,
        value,
        onChange,
        helperText,
        error,
        ...rest
    }
) => {
    let messageList = helperText;

    if (helperText && !Array.isArray(helperText)) {
        messageList = [helperText];
    }

  return (
      <div className="form-group">
          {label && <label>{label}</label>}
          <textarea
              rows={rows}
              className={clsx('form-control', className, error && 'invalid')}
              value={value}
              onChange={onChange}
              {...rest}
           />
          {messageList?.map((item) => (
              <div
                  key={`helperText-${item}`}
                  className={clsx('pt-1 text-xs text-grey', error && 'error-color')}
              >{item}</div>
          ))}
      </div>
  );
};

TextAreaInput.propTypes = {
  rows: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

TextAreaInput.defaultProps = {
  rows: 5,
  value: undefined,
  onChange: undefined,
  className: '',
};

export default TextAreaInput;
