import intl from "./i18n/translate";

const wishlist = {
    setBoardPreviewHeight() {
        const previewImgItemHeight = $('.preview-img', '.wishlist-board-card').outerHeight(true);
        $('.board-preview-items', '.wishlist-board-card').innerHeight(previewImgItemHeight * 2);
    },

    init() {
        this.setBoardPreviewHeight();
        $('.wishlist-board-list').removeClass('invisible');

        $(window).on('resize', () => {
            this.setBoardPreviewHeight();
        });

        $(document).on("click", '.actions-button-favorite', function (ev) {
            let productId = $(this).data('id');
            let locale = window.location.pathname.split('/')[1];
            $("#boardsListPreview").attr('data-id', productId);
            if (!$(this).hasClass('active')) {
                $(this).parent().find('.wishlist-heart').attr('fill', '#486AF3');
                $(this).addClass('active');
                $.ajax({
                    url: `/${locale}/profile/wish-list/items`,
                    data: {"productId" : productId},
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        if (!data.added) {
                            $('.board-from-info .content').html(data.text);
                            $('.board-from-info').attr('data-id', data.fromId);
                            loadBoards();
                        }

                        if (data.added && data.added.text && data.added.link && data.added.linkText) {
                            toastr.success(
                                null,
                                `${data.added.text} <a class="nav-link" style="color: blue" href="${data.added.link}">${data.added.linkText}</a>`
                            );
                        }
                    },
                    error: (err) => {
                        if (err.status === 401) {
                            $('.open-auth-btn').trigger('click');
                            $(this).parent().find('.wishlist-heart').attr('fill', 'none');
                            $(this).removeClass('active');
                        }
                    }
                });
            } else {
                $(this).parent().find('.wishlist-heart').attr('fill', 'none');
                $(this).removeClass('active');
                $.ajax({
                    url: `/${locale}/profile/wish-list/items/${productId}`,
                    type: 'POST',
                    success: function (data) {
                        toastr.success(
                            null,
                            intl.translate('toastrMessage.wishlist-removed-success')
                        );
                    },
                    error: (err) => {
                        toastr.error(null, 'Error');
                    }
                });
            }

            return false;
        });

        $(document).on('click', '.delete-selected', function () {
            var tabAttribs = [];
            $('input:checked').each(function () {
                tabAttribs.push( parseInt($(this).attr("name"), 10));
            });

            if (tabAttribs.length <= 0) {
                toastr.error(null, intl.translate('toastrMessage.wishlist-no-items-selected'));

                return false;
            }

            $("#confirmDeleteModal").modal('show');
        });

        $(document).on('submit', '.fn-delete-items', function () {
            var tabAttribs = [];
            $('input:checked').each(function () {
                tabAttribs.push( parseInt($(this).attr("name"), 10));
            });
            $('#deleteItemsIds').val(tabAttribs);
        });

        $(document).on('change', '.select-wishlist-item', function () {
            let selectAllBtn = $('.wishlist-select-all');
            if ($('.select-wishlist-item:checked').length === $('.select-wishlist-item').length) {
                selectAllBtn.prop('disabled', true);

                return true;
            }

            selectAllBtn.prop('disabled', false);
        });

        $(document).on('click', '.wishlist-select-all', function () {
            $('.select-wishlist-item').prop('checked', true);
            $(this).prop('disabled', true);
        });

        $(document).on('click', '.createBoardBtn', () => {
            $("#boardsListPreview").modal('hide');
            $("#createBoard").modal('show');
        });

        $('.createBoardBtn-from').on('click', () => {
            $("#createBoard-form").modal('show');
        });

        $('.dropdown-menu', '.wishlist-board-card').click((e) => {
            e.preventDefault();
        });

        $('.edit-board', '.wishlist-board-card').on('click', (e) => {
            const parentContainer = $(e.delegateTarget).parents('.wishlist-board-card');
            const boardId = parentContainer.data('id');
            const boardName = parentContainer.data('name');
            const boardVisible = parentContainer.data('visible');
            const link = parentContainer.data('link');

            $("#formBoardId").val(boardId);
            $("#editBoardName").val(boardName);
            $('.edit-board-from').attr('action', link);
            $(`#editBoardType option[value="${boardVisible}"]`).prop('selected', true);
            $("#editBoard").modal('show');
        });

        $('.delete-board', '.wishlist-board-card').on('click', (e) => {
            const parentContainer = $(e.delegateTarget).parents('.wishlist-board-card');
            const boardId = parentContainer.data('id');
            $("#deleteBoardId").val(boardId);
            $("#confirmDeleteBoardModal").modal('show');
        });

        $(document).on('click', '.move-selected', function () {
            var tabAttribs = [];
            $(this).attr('data-reload', true);
            $('input:checked').each(function () {
                tabAttribs.push( parseInt($(this).attr("name"), 10));
            });

            if (tabAttribs.length <= 0) {
                toastr.error(null, intl.translate('toastrMessage.wishlist-no-items-selected'));

                return false;
            }

            loadBoards();
        });

        $(document).on('click', '.moveToBoard', function () {
            let fromBoard = $('.move-selected').data('from');
            const toBoard = $(this).data('id');
            const locale = window.location.pathname.split('/')[1];
            const tabAttribs = [];
            $('input:checked').each(function () {
                tabAttribs.push( parseInt($(this).attr("name"), 10));
            });

            tabAttribs.push(parseInt($("#boardsListPreview").attr('data-id'), 10) );

            if (fromBoard == null) {
                fromBoard = 0
            }

            moveBoardItem(locale, toBoard, fromBoard, tabAttribs);
        });

        $(document).on('click', '.submitCreateBoard', function () {
           let name = $("#boardName").val(),
               boardType = $("#boardType").val(),
               locale = window.location.pathname.split('/')[1];

            $.ajax({
                url: `/${locale}/profile/wish-list/create-board`,
                data: {
                    name,
                    boardType
                },
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    $("#createBoard").modal('hide');
                    loadBoards();
                },
                error: (err) => {

                }
            });
        });

        function moveBoardItem(locale, toBoard, fromBoard, itemsIds) {
            $.ajax({
                url: `/${locale}/profile/wish-list/items/attach`,
                data: {
                    "productIds" : itemsIds,
                    "reload" : $(".move-selected").attr('data-reload'),
                    "toBoard": toBoard,
                    "fromBoard" : fromBoard
                },
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    if ( $(".move-selected").attr('data-reload')) {
                        window.location.reload();
                        return false;
                    }
                    $("#boardsListPreview").modal('hide');
                    toastr.success(
                        null,
                        `${data.success.text} <a class="nav-link" href="${data.success.link}">${data.success.linkText}</a>`
                    );
                },
                error: (err) => {
                    console.log(err)
                }
            });
        }

        $(document).on('click', '.like-wishlist-board', async function (e) {
            e.preventDefault();
            const boardId = $(this).data('id');
            const locale = window.location.pathname.split('/')[1];

            if (!$(this).hasClass('active')) {
                try {
                    await likeBoard('POST', locale, boardId);
                    $(this).parent().find('.wishlist-heart-board').attr('fill', '#486AF3');
                    $(this).addClass('active');
                    toastr.success(null, intl.translate('toastrMessage.wishlist-like-board-success'));
                } catch (e) {
                    if (e.status === 401) {
                        $('.open-auth-btn').trigger('click');
                        $(this).parent().find('.wishlist-heart').attr('fill', 'none');
                        $(this).removeClass('active');
                        return false;
                    }
                    toastr.error(null, intl.translate('toastrMessage.wishlist-like-board-error'));
                }

            }
            else {
                try {
                    await likeBoard('DELETE', locale, boardId);
                    $(this).parent().find('.wishlist-heart-board').attr('fill', 'none');
                    $(this).removeClass('active');
                    toastr.success(null, intl.translate('toastrMessage.wishlist-unlike-board-success'));
                } catch (e) {
                    toastr.error(null, intl.translate('toastrMessage.wishlist-unlike-board-error'));
                }
            }

        });

        function likeBoard(requestType, locale, boardId) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: `/${locale}/wish-list/boards/${boardId}/like`,
                    type: requestType,
                    success: (data) => {
                        resolve(data);
                    },
                    error: (error) => {
                        reject(error)
                    }
                });
            });
        }

        $('.share-board').on('click', function (e) {
            e.preventDefault();
           let boardId = $(this).data('boardid'),
               locale = window.location.pathname.split('/')[1];

            $.ajax({
                url: `/${locale}/wish-list/boards/${boardId}/share`,
                type: 'POST',
                success: function (data) {
                    $("#socialsShare .modal-content").html(data);
                    $("#socialsShare").modal('show');
                },
                error: (error) => {
                    console.log("err")

                }
            });
        });

        var popupSize = {
            width: 780,
            height: 550
        };

        $(document).on('click', '.social-buttons > a', function(e) {

            var
                verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
                horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2);

            var popup = window.open($(this).prop('href'), 'social',
                'width='+popupSize.width+',height='+popupSize.height+
                ',left='+verticalPos+',top='+horisontalPos+
                ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

            if (popup) {
                popup.focus();
                e.preventDefault();
            }

        });

        $(document).on('click', '.share-board-copy', function () {
            navigator.clipboard.writeText($(this).data('url'));
            toastr.success(null, 'Copied');
            $("#socialsShare").modal('hide');
        });

        function loadBoards() {
            let locale = window.location.pathname.split('/')[1];
            let fromBoard = $('.move-selected').data('from');

            if (fromBoard == undefined) {
                fromBoard =  $('.board-from-info').attr('data-id');
            }

            $.ajax({
                url: `/${locale}/profile/wish-list/boards-list?ignore=${fromBoard}`,
                type: 'GET',
                success: function (data) {
                    $("#boardsListPreview .modal-content").html(data);
                    $("#boardsListPreview").modal('show');
                },
                error: (err) => {
                    console.log(err)
                }
            });
        }
    }
};

export default wishlist;
