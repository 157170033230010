const en = {
    // create review form
    'createReview.modalTitle': 'Your overall rating',
    'createReview.inputPlaceholder': 'What do you like or don\'t like?',
    'createReview.submitBtnText': 'Submit a review',

    // add product form
    'addProductForm.file': 'File',
    'addProductForm.addFile': 'Add file',
    'addProductForm.replaceFile': 'Replace file',
    'addProductForm.dropFiles': 'or drop files to upload',
    'addProductForm.csvTemplate': 'CSV template',
    'addProductForm.overwriteProducts': 'Overwrite any current products that have the same handle. Existing values will be used for any missing columns.',
    'addProductForm.startImporting': 'Start Importing',

    'addProductForm.mainInfo': 'Main Info',
    'addProductForm.productImages': 'Product Images',
    'addProductForm.addImages': 'Add images',
    'addProductForm.languages': 'Languages',
    'addProductForm.autoTranslate': 'Auto-translate',
    'addProductForm.autoTranslateDescription': 'When enabled, the product name and description is published in two languages by auto-translation. You can edit the text manually by selecting the second language tab',
    'addProductForm.productName': 'Product Name',
    'addProductForm.sku': 'SKU',
    'addProductForm.sellerPageLabel': 'Seller\'s page URL',
    'addProductForm.sellerPagePlaceholder': 'Enter URL',
    'addProductForm.category': 'Category',
    'addProductForm.selectCategory': 'Select category',
    'addProductForm.subCategory': 'Sub Category',
    'addProductForm.selectSubCategory': 'Select sub-category',
    'addProductForm.childSubCategory': 'Child Sub Category',
    'addProductForm.selectChildSubCategory': 'Select child sub-category',
    'addProductForm.description': 'Description',
    'addProductForm.descriptionPlaceholder': 'Tell us about your product',
    'addProductForm.price': 'Price',
    'addProductForm.discount': 'Discount',
    'addProductForm.discountPlaceholder': 'Enter discount',
    'addProductForm.quantity': 'Quantity',

    'addProductForm.productOptions': 'Product Options',
    'addProductForm.optionsDescription': 'For products with different options like color, size etc.',
    'addProductForm.option': 'Option',
    'addProductForm.select': 'Select',
    'addProductForm.values': 'Values',

    'addProductForm.productSpecs': 'Product Specifications',
    'addProductForm.specsDescription1': 'Product specifications, which will be displayed in the filters on the platform',
    'addProductForm.specsDescription2': 'Can\'t find the right specification?',
    'addProductForm.contactUs': 'Contact Us',

    'addProductForm.seo': 'SEO options',
    'addProductForm.productKeywords': 'Product keywords',
    'addProductForm.keywordsPlaceholder': 'Type keyword and press space or enter...',

    'addProductForm.publish': 'Publish',
    'addProductForm.cancel': 'Cancel',

    // validation errors
    'validationError.required': 'This field is required',
    'validationError.passwordsShouldMatch': 'Passwords should match',
    'validationError.emailInvalid': 'Email must be a valid email address',
    'validationError.invalidSize': 'is more than 2Mb',
    'validationError.alreadyExists': 'already added to list',
    'validationError.fullList': 'is not added, since list is already full',

    //toastr messages
    'toastrMessage.unauthorized': 'You must be logged in to perform this action.',

    'addProductVote.success': 'You successfully voted',
    'addProductVote.error': 'Product review vote already exists',
    'toastrMessage.wishlist-no-items-selected': 'No items selected',
    'toastrMessage.wishlist-like-board-success': 'Board saved',
    'toastrMessage.wishlist-like-board-error': 'Board save failed',
    'toastrMessage.wishlist-unlike-board-success': 'Board removed form saved',
    'toastrMessage.wishlist-unlike-board-error': 'Board remove failed',
    'toastrMessage.wishlist-removed-success': 'Product removed from all boards',
    'toastrMessage.contact-us.success.head': 'Thank you!Your message has been sent',
    'toastrMessage.contact-us.success.body': 'We will read it and reply to your email as soon as possible',
    'toastrMessage.contact-us.error': 'Something went wrong',
};

export default en;
