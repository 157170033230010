import forgotPasswordValidator from "./forgotPasswordValidator";
import resetPasswordValidator from "./resetPasswordValidator";

const authSidebar = {
    submitRegisterFormUrl: '/register',
    sidebar: $('#auth-sidebar'),
    overlay: $(document.createElement('div')).addClass('overlay'),

    openSidebar() {
        const closeSidebar = this.closeSidebar.bind(this);

        $("body").addClass('disabled-scroll');
        $("body").append(this.overlay);

        setTimeout(() => {
            this.overlay.addClass('active');
        }, 0);

        this.sidebar.addClass('open');
        this.overlay.on('click', closeSidebar);
    },

    closeSidebar() {
        this.overlay.removeClass('active');
        this.sidebar.removeClass('open');
        setTimeout(() => {
            this.overlay.remove();
            $("body").removeClass('disabled-scroll');
            this.resetState();
        }, 300);
    },

    resetState() {
        this.showAuth();
        $('#customer-signup-switcher').click();
        $('.tabItem[data-for="sign-in"]').click();
        $('#auth-sidebar form').each(function () {
            this.reset();
        });
        $('#auth-sidebar input').each(function () {
            $(this).removeClass('invalid');
            $(this).siblings('.invalid-feedback').remove();
        });
    },

    showAuth() {
        $('#auth-sidebar .auth-section').each(function () {
            $(this).hide();
        });
        $('#backToLoginBtn').hide();
        $('#auth-tabs').show();
    },

    showForgot() {
        $('#auth-sidebar .auth-section').each(function () {
            $(this).hide();
        });
        $('#backToLoginBtn').show();
        $('#forgot-password-enter-email').show();
    },

    showSuccessSentMessage() {
        $('#auth-sidebar .auth-section').each(function () {
            $(this).hide();
        });
        $('#backToLoginBtn').hide();
        $('#forgot-password-request-sent').show();
    },

    showSetNewPassword(token, email) {
        $('#auth-sidebar .auth-section').each(function () {
            $(this).hide();
        });
        $('#backToLoginBtn').hide();
        $('#set-new-password').show();

        $('#reset-email').val(email);
        $('#reset-token').val(token);

    },

    showTwoFactorAuth(user) {
        $('#auth-sidebar .auth-section').each(function () {
            $(this).hide();
        });
        $('#backToLoginBtn').hide();
        $('#two-factor-auth').show();
        $('#user2fa').val(user);

    },

    showValidationErrors(form, errors) {
        const errorKeys = Object.keys(errors);

        form.find('input').each(function () {
            $(this).siblings('.invalid-feedback').remove();
        });

        errorKeys.forEach((key) => {
            const currentInput = $(form.find(`[name=${key}]`)[0]);
            const isCustomInput = currentInput.parent().hasClass('customInput');
            const targetEl = isCustomInput ? currentInput.parent() : currentInput
            targetEl.addClass('invalid')

            errors[key].forEach((errMessage) => {
                targetEl.after(`<div class="invalid-feedback">${errMessage}</div>`);
            });
        });
    },

    validateForm(form, validatorFn) {
        const formValuesObj = {};

        form.serializeArray().forEach(({name, value}) => {
            formValuesObj[name] = value;
        });

        const errorsObj = validatorFn(formValuesObj);

        this.showValidationErrors(form, errorsObj);

        return !Object.keys(errorsObj).length
    },

    getLocale() {
        let locale = 'es';
        const url = $(location).attr('href').split('/').splice(0, 5).join('/');
        const segments = url.split('/');

        if (segments[3] !== undefined) {
            const localeSegment = segments[3];
            let lang = localeSegment.substring(0, localeSegment.indexOf('?'));

            if (lang) {
                locale = lang;
            }
        }

        return locale;
    },

    init() {
        const urlParams = new URLSearchParams(window.location.search);
        const restoreToken = urlParams.get('restoreToken');
        const userEmail = urlParams.get('user');

        const self = this;
        const openSidebar = this.openSidebar.bind(this);
        const closeSidebar = this.closeSidebar.bind(this);

        if (restoreToken) {
            this.showSetNewPassword(restoreToken, userEmail);
            this.openSidebar();
        } else {
            this.showAuth();
        }

        $('.open-auth-btn').on('click', openSidebar);
        $('#shop-products-button-guest-customer').on('click', openSidebar);
        $('#shop-products-button-guest-seller').on('click', openSidebar);
        $('#start-selling-button').on('click', openSidebar);
        $('#start-selling-button-2').on('click', openSidebar);
        $('.contactSupportOpenAuth').on('click', openSidebar);
        $('[data-target="#openAuth"]').on('click', openSidebar);
        $('#auth-sidebar .closeBtn').on('click', closeSidebar);

        $('#auth-sidebar input').on('input', function () {
            $(this).removeClass('invalid');
            $(this).siblings('.invalid-feedback').remove();
        });

        $('.customInput input').on('input', function () {
            $(this).parent().removeClass('invalid');
            $(this).parent().siblings('.invalid-feedback').remove();
        });

        $('#login-form').on('submit', function (e) {
            e.preventDefault();

            const currentForm = $(this);

            let locale = self.getLocale();
            let formData = $(this).serialize() + `&locale=${locale}`;

            $.ajax({
                url: '/login',
                data: formData,
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    if (data.twoFactorRedirect == true) {
                        self.showTwoFactorAuth(data.user);
                    } else {
                        window.location.href = data.redirect;
                    }
                },
                error: (err) => {
                    if (err.status === 422) {
                        const errorsObj = err.responseJSON.errors;
                        self.showValidationErrors(currentForm, errorsObj);
                    }
                }
            });
        });

        $('#two-factor-auth-form').on('submit', function (e) {
            e.preventDefault();

            const currentForm = $(this);
            let locale = self.getLocale();

            $.ajax({
                url: `/${locale}/email/two-factor-authentication`,
                data: $(this).serialize(),
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    window.location.href = data.redirect;
                },
                error: (err) => {
                    if (err.status === 422) {
                        const errorsObj = err.responseJSON.errors;
                        self.showValidationErrors(currentForm, errorsObj);
                    }
                }
            });
        });

        $('#register-seller-form').on('submit', function (e) {
            e.preventDefault();

            let locale = self.getLocale();
            let formData = $(this).serialize() + `&locale=${locale}`;

            $.ajax({
                url: self.submitRegisterFormUrl,
                data: formData,
                type: 'POST',
                dataType: 'json',
                success: function () {
                    window.location.search += '&showVerifyEmailModal=true';
                },
                error: (err) => {
                    if (err.status === 422) {
                        const errorsObj = err.responseJSON.errors;
                        self.showValidationErrors($(this), errorsObj);
                    }
                }
            });
        });

        $('#send-recover-link-form').on('submit', function (e) {
            console.log('Submit forgot form');
            e.preventDefault();

            const currentForm = $(this);
            const isFormValid = self.validateForm(currentForm, forgotPasswordValidator);

            if (!isFormValid) return;

            $.ajax({
                url: '/forgot-password',
                data: $(this).serialize(),
                type: 'POST',
                dataType: 'json',
                success: function () {
                    console.log('Success')
                    self.showSuccessSentMessage();
                },
                error: (err) => {
                    if (err.status === 422) {
                        const errorsObj = err.responseJSON.errors;
                        self.showValidationErrors(currentForm, errorsObj);
                    }
                }
            });
        });

        $('#set-new-password-form').on('submit', function (e) {
            e.preventDefault();

            const currentForm = $(this);
            const isFormValid = self.validateForm(currentForm, resetPasswordValidator);

            if (!isFormValid) return;

            $.ajax({
                url: `/reset-password`,
                data: $(this).serialize(),
                type: 'POST',
                dataType: 'json',
                success: function () {
                    window.location.href = '/';
                },
                error: (err) => {
                    if (err.status === 422) {
                        const errorsObj = err.responseJSON.errors;
                        self.showValidationErrors(currentForm, errorsObj);
                    }
                }
            });
        })

        $('#seller-signup-switcher').on('click', 'button', function () {
            $(this).parent().hide();
            $('#seller-signup-label').show();
            $('#customer-signup-switcher').show();
            // $('#social-seller-register').show();
            $('#social-customer-register').hide();
            $('#quick-access-text').hide();
            $('#or-divider').hide();
            self.submitRegisterFormUrl = '/seller/register';
        });

        $('#customer-signup-switcher').on('click', function () {
            $(this).hide();
            $('#seller-signup-label').hide();
            $('#seller-signup-switcher').show();
            // $('#social-seller-register').hide();
            $('#social-customer-register').show();
            $('#quick-access-text').show();
            $('#or-divider').show();
            self.submitRegisterFormUrl = '/register';
        });

        $('#forgotPasswordBtn').on('click', this.showForgot);

        $('#backToLoginBtn button').on('click', this.showAuth);

        $('#typoInEmail').on('click', this.showForgot)

        $('#resendEmail').on('click', () => {
            $('#send-recover-link-form').submit();
        });

        $('#resendCodeBtn').on('click', () => {
            const currentForm = $('#two-factor-auth-form');

            $.ajax({
                url: '/es/email/two-factor-authentication-resend',
                data: currentForm.serialize(),
                type: 'POST',
                dataType: 'json',
                success: function () {
                    console.log('Success')
                },
                error: (err) => {
                    if (err.status === 422) {
                        const errorsObj = err.responseJSON.errors;
                        self.showValidationErrors(currentForm, errorsObj);
                    }
                }
            });
        })
    }
};

export default authSidebar;
