const emailPattern = /.+@.+..+/;
const phonePattern = /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/;

const createStore = {
    readURL(input) {
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const preview = $(document.createElement('img'));
                preview.attr('src', e.target.result);
                $('.avatar-wrapper').append(preview);
            }

            reader.readAsDataURL(input.files[0]);
        }
    },

    goToSecondStep() {
        $('#first-step-form').hide();
        $('#second-step-form').show();
        $('#step-counter').text(2);
        $('#first-step-title').hide();
        $('#second-step-title').show();
    },

    backToFirstStep() {
        $('#first-step-form').show();
        $('#second-step-form').hide();
        $('#step-counter').text(1);
        $('#first-step-title').show();
        $('#second-step-title').hide();
    },

    goToSuccessScreen() {
        $('#second-step-form').hide();
        $('#success-step').show();
        $('#steps-header').hide();
    },

    validateSecondStep({ legaladdress, shopemail, shopphone }) {
        const errors = {}

        if (!legaladdress) {
            errors.legaladdress = ['This field is required'];
        }

        if (!shopemail) {
            errors.shopemail = ['This field is required'];
        } else if (!emailPattern.test(shopemail)) {
            errors.shopemail = ['Email must be a valid email address'];
        }

        if (!shopphone) {
            errors.shopphone = ['This field is required'];
        } else if (!phonePattern.test(shopphone)) {
            errors.shopphone = ['Email must be a valid phone number'];
        }

        return errors;
    },

    showErrorMessages(errorsObj, form) {
        form.find('input').each(function () {
            $(this).removeClass('invalid');
            $(this).siblings('.invalid-feedback').remove();
        });

        form.find('textarea').each(function () {
            $(this).removeClass('invalid');
            $(this).siblings('.invalid-feedback').remove();
        });

        Object.keys(errorsObj).forEach((key) => {
            const currentInput = $(form.find(`[name=${key}]`)[0]);

            currentInput.addClass('invalid');
            errorsObj[key].forEach((errMessage) => {
                currentInput.after(`<div class="invalid-feedback">${errMessage}</div>`)
            });
        });
    },

    init() {
        const self = this;
        const avatarInput = $('#first-step-form [type="file"]');
        const formData = new FormData();


        avatarInput.bind('change', function() {
            if (this.files.length) {
                if (this.files[0].size/1024/1024 > 2) {
                    $(this).val('');
                    $('.avatar-wrapper img').remove();
                    $('.max-size-hint').addClass('invalid');
                    return;
                }
                $('.max-size-hint').removeClass('invalid');
                self.readURL(this);
            } else {
                $('.max-size-hint').removeClass('invalid');
                $('.avatar-wrapper img').remove();
            }
        });

        $('#first-step-form').on('submit', function(e) {
            e.preventDefault();

            const currentForm = $(this);

            if (avatarInput[0].files.length) {
                if (formData.has('logo')) {
                    formData.set('logo', avatarInput[0].files[0])
                } else {
                    formData.append('logo', avatarInput[0].files[0]);
                }
            } else {
                formData.delete('logo');
            }

            $(this).serializeArray().forEach(({ name, value }) => {
                if (!formData.has(name)) {
                    formData.append(name, value);
                } else {
                    formData.set(name, value);
                }
            });


            $.ajax({
                type: 'GET',
                url: '/es/store/validate',
                data: $(this).serialize(),
                success: function () {
                    self.goToSecondStep();
                },
                error: (err) => {
                    if (err.status === 422) {
                        self.showErrorMessages(err.responseJSON, currentForm);
                    }
                }
            });
        });

        $('#previous-step-btn').on('click', self.backToFirstStep);

        $('#second-step-form').on('submit', function (e) {
            e.preventDefault();

            const currentForm = $(this);
            const formValuesObj = {}

            $(this).serializeArray().forEach(({ name, value }) => {
                formValuesObj[name] = value;
            });

            const frontValidationErrors = self.validateSecondStep(formValuesObj);

            if (Object.keys(frontValidationErrors).length) {
                self.showErrorMessages(frontValidationErrors, currentForm);
                return;
            }

            $(this).serializeArray().forEach(({ name, value }) => {
                if (!formData.has(name)) {
                    formData.append(name, value);
                } else {
                    formData.set(name, value);
                }
            });

            $.ajax({
                url: '/es/store/save',
                data: formData,
                type: 'POST',
                processData: false,
                contentType: false,
                success: () => {
                    self.goToSuccessScreen();
                },
                error: (err) => {
                    if (err.status === 422) {
                        self.showErrorMessages(err.responseJSON, currentForm);
                    }
                }
            });
        })
    }
};

export default createStore;
