const es = {
    // create review form
    'createReview.modalTitle': 'Tu calificación general',
    'createReview.inputPlaceholder': '¿Qué te gusta o no te gusta?',
    'createReview.submitBtnText': 'Envíe una reseña',

    //add product form
    'addProductForm.file': 'Archivo',
    'addProductForm.addFile': 'Agregar archivo',
    'addProductForm.replaceFile': 'Reemplazar el archivo',
    'addProductForm.dropFiles': 'o soltar archivos para cargar',
    'addProductForm.csvTemplate': 'CSV plantilla',
    'addProductForm.overwriteProducts': 'Sobrescriba cualquier producto actual que tenga el mismo identificador. Los valores existentes se utilizarán para las columnas que falten.',
    'addProductForm.startImporting': 'Empezar a importar',

    'addProductForm.mainInfo': 'Información principal',
    'addProductForm.productImages': 'Imágenes del producto',
    'addProductForm.addImages': 'Añadir imágenes',
    'addProductForm.languages': 'Idiomas',
    'addProductForm.autoTranslate': 'Traducir automáticamente',
    'addProductForm.autoTranslateDescription': 'Cuando está habilitado, el nombre y la descripción del producto se publican en dos idiomas mediante traducción automática. Puede editar el texto manualmente seleccionando la pestaña del segundo idioma',
    'addProductForm.productName': 'Nombre del producto',
    'addProductForm.sku': 'SKU',
    'addProductForm.sellerPageLabel': 'URL de página del vendedor',
    'addProductForm.sellerPagePlaceholder': 'Introducir URL',
    'addProductForm.category': 'Categoría',
    'addProductForm.selectCategory': 'Selecciona una categoría',
    'addProductForm.subCategory': 'Subcategoría',
    'addProductForm.selectSubCategory': 'Seleccionar subcategoría',
    'addProductForm.childSubCategory': 'Subcategoría infantil',
    'addProductForm.selectChildSubCategory': 'Seleccionar subcategoría secundaria',
    'addProductForm.description': 'Descripción',
    'addProductForm.descriptionPlaceholder': 'Cuéntanos sobre tu producto',
    'addProductForm.price': 'Precio',
    'addProductForm.discount': 'Descuento',
    'addProductForm.discountPlaceholder': 'Ingrese descuento',
    'addProductForm.quantity': 'Cantidad',

    'addProductForm.productOptions': 'Opciones de producto',
    'addProductForm.optionsDescription': 'Para productos con diferentes opciones como color, tamaño, etc.',
    'addProductForm.option': 'Opción',
    'addProductForm.select': 'Seleccione',
    'addProductForm.values': 'Valores',

    'addProductForm.productSpecs': 'Especificaciones del producto',
    'addProductForm.specsDescription1': 'Especificaciones del producto, que se mostrarán en los filtros de la plataforma.',
    'addProductForm.specsDescription2': '¿No puede encontrar la especificación correcta?',
    'addProductForm.contactUs': 'Contacta con nosotros',

    'addProductForm.seo': 'Opciones de SEO ',
    'addProductForm.productKeywords': 'Palabras clave de producto',
    'addProductForm.keywordsPlaceholder': 'Escriba la palabra clave y presione la barra espaciadora o ingrese ...',

    'addProductForm.publish': 'Publicar',
    'addProductForm.cancel': 'Cancelar',

    // validation errors
    'validationError.required': 'Este campo es obligatorio',
    'validationError.passwordsShouldMatch': 'Las contraseñas deben coincidir',
    'validationError.emailInvalid': 'El correo electrónico debe ser una dirección de correo electrónico válida',
    'validationError.invalidSize': 'tiene más de 2 Mb',
    'validationError.alreadyExists': 'ya agregado a la lista',
    'validationError.fullList': 'no se agrega, ya que la lista ya está llena',

    //toastr messages
    'toastrMessage.unauthorized': 'Debes ingresar para realizar esta acción',

    'addProductVote.success': 'Votaste exitosamente',
    'addProductVote.error': 'El voto de revisión del producto ya existe',
    'toastrMessage.wishlist-no-items-selected': 'No se seleccionó ningún elemento',
    'toastrMessage.wishlist-like-board-success': 'Tablero guardado',
    'toastrMessage.wishlist-like-board-error': 'No se pudo guardar el tablero',
    'toastrMessage.wishlist-unlike-board-success': 'Se guardó el formulario eliminado del tablero',
    'toastrMessage.wishlist-unlike-board-error': 'Error al quitar la placa',
    'toastrMessage.wishlist-removed-success': 'Producto eliminado de todos los tableros',
    'toastrMessage.contact-us.success.head': 'Gracias! Tu mensaje ha sido enviado',
    'toastrMessage.contact-us.success.body': 'Lo leeremos y responderemos a su correo electrónico lo antes posible.',
    'toastrMessage.contact-us.error': 'Algo salió mal',
};

export default es;
